@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.MobileHeaderContainer {
  position: absolute;
  top: 0;
  z-index: 15;
  width: 100%;
  display: flex;
  justify-content: space-between;
  &__justifyContent {
    &_flexEnd {
      justify-content: flex-end;
    }
  }
}

.MainPageLink {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 22px;
  margin-top: 110px;
  cursor: pointer;
  &__hide {
    display: none;
  }
}
.ArrowLeftIcon {
  margin-right: 12px;
}

.MainPageText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: $white;
}

.AvatarImage {
  display: flex;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  margin-right: 30px;
  cursor: pointer;
  position: relative;
  bottom: -4px;
  &__hide {
    display: none;
  }
}

.HamburgerMenuContainer {
  z-index: 20;
  position: fixed;
  left: 30px;
  top: 30px;
  cursor: pointer;
}

.HamburgerMenuImage {
  width: 60px;
}

.LogoutIconImage {
  width: 15px;
  // winter - filter: invert(40%) sepia(77%) saturate(900%) hue-rotate(170deg) brightness(100%) contrast(100%);
}

.LogoutText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #4f7a42; // winter - #20adff; summer - #4f7a42;
  margin-right: 13px;
}

.LogoutButtonContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-right: 23px;
  margin-top: 41px;
  cursor: pointer;
}
