@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

// @mixin web {
//   padding-top: 7px;
//   margin-bottom: -3px;
//   z-index: 1;
//   width: calc(5vw);
//   height: 100%;
// }

// .webLeft {
//   @include web;
//   margin-right: calc(-1.7vw);
// }

// .SpiderIcon {
//   position: absolute;
//   left: 4vw;
//   width: 10vw;
//   top: -2.4vw;
//   z-index: 2;
// }

// .SpiderShadow {
//   position: absolute;
//   left: 4vw;
//   width: 10vw;
//   z-index: 2;
// }

// .webRight {
//   @include web;
//   margin-left: calc(-1.7vw);
//   transform: scaleX(-1);
// }

// .headerLight {
//   position: absolute;
//   height: 100%;
// }

.TitleModalContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 108px;
  height: auto;
  display: flex;
  margin: auto;
  justify-content: center;
  z-index: 1;

  &__isMedium {
    top: -30px;
  }
}
.TitleModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // background-color: #534845;
  border-radius: 50px;
  // box-shadow: 0px 6.72px 10.08px rgba(0, 0, 0, 0.1), inset 0px -9px 3.36px #473C39;
}
.Title {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 40px; // 100
  line-height: 40px; // 120
  z-index: 2;
  /* or 100% */
  display: flex;
  // align-items: center;
  // text-align: center;

  color: $white;
  @include textBorder_2px_shadow(#9A1F05);
  &__isMedium {
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
  }
}

@media screen and (max-width: 1000px) {
  // .webLeft {
  //   width: calc(8vw);
  //   margin-right: calc(-2.7vw);
  //   margin-left: 0;
  // }

  // .SpiderIcon {
  //   left: 6vw;
  //   width: 10vw;
  //   top: -2vw;
  // }

  // .SpiderShadow {
  //   left: 6vw;
  //   width: 10vw;
  // }

  // .webRight {
  //   width: calc(8vw);
  //   margin-left: calc(-2.7vw);
  //   margin-right: 0;
  // }

  .TitleModalContainer {
    height: auto;
    top: 130px;
    // &__isSmall {
    //   // top: -3vw;
    // }
    // &__isMedium {
    //   top: 108px;
    // }
    // &__isLarge {
    //   top: 108px;
    // }
    // &__isUnityGame {
    //   &__isSmall {
    //     width: auto;
    //     min-width: 240px;
    //     top: -15px;
    //   }
    //   &__isMedium {
    //     top: -25px;
    //     width: auto;
    //     min-width: 240px;
    //   }
    // }
  }
  .Title {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;

    &__isUnityGame {
      &__isSmall {
        font-size: 16px;
        line-height: 18px;
      }
      &__isMedium {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  // .webLeft {
  //   width: calc(12vw);
  //   margin-right: calc(-3vw);
  //   margin-left: 0;
  // }

  // .webRight {
  //   width: calc(12vw);
  //   margin-left: calc(-3vw);
  //   margin-right: 0;
  // }

  // .SpiderIcon {
  //   left: 11vw;
  //   width: 10vw;
  //   top: -2vw;
  // }

  // .SpiderShadow {
  //   left: 11vw;
  //   width: 10vw;
  // }
}