@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.LayoutComponent {
  background: $green_mobile;
  overflow: hidden;

  &__hide {
    display: none;
  }

  &_position {
    &__fixed {
      position: fixed;
    }
  }
}

.SnowContainer {
  position: absolute;
  top: 0;
  z-index: 10;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  /* height: calc(100vh - 5000px); */
  // background-color: blue;
  // width: 100%;
  // height: 100vh;
}

.snowflake {
  position: absolute;
  width: 10px;
  height: 10px;
  background: linear-gradient(white, white);
  /* Workaround for Chromium's selective color inversion */
  border-radius: 50%;
  filter: drop-shadow(0 0 10px white);
}

.ChildrenContainer {
  position: relative;

  &__mobile {
    background: $green_mobile;
  }
}

.CookiesPopUpBlock {
  z-index: 20;
  position: fixed;
  bottom: 10px;
  right: 180px;
  left: 0;
  display: flex;
  justify-content: center;
  overflow-x: hidden;

  &__mobile {
    width: 100%;
    right: unset;
  }
}

.CookiesManageModalBlock {
  z-index: 13;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  background: rgba(28, 28, 28, 0.8);
  height: 100%;
  width: 100%;
}

.ArrowUpButtonContainer {
  z-index: 10;
  position: fixed;
  bottom: 35px;
  right: 35px;

  &__mobile {
    display: none;
  }
}
