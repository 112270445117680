@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.MenuContainerShadow {
  background-color: rgba($black, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  display: flex;
  overflow-x: hidden;
}

.ModalClose {
  padding: 0;
  border: none;
  background: none;
  z-index: 10;
  position: fixed;
  left: 30px;
  top: 30px;
  cursor: pointer;
  & img {
    width: 60px;
  }
}

.LinksContainer {
  margin-bottom: 80px;
}

.MenuContainer {
  position: absolute;
  width: 100%;
  min-height: 100%;
  background-color: $dark_green_mobile;
  padding: 40px 24px 60px;

  &__item {
    display: flex;
    height: 40px;
    width: 160px;
    margin-bottom: 20px;
    position: relative;
    flex: 0;
    text-decoration: none; // for Mozilla
    white-space: nowrap;
  }

  &__title {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    padding: 8px 0px;
    display: flex;
    color: $white;
    cursor: pointer;

    &_SellingPack {
      background: #fb4517;
      box-shadow: 0px 3px 5px
          rgba(0, 0, 0, 0.25),
        inset 0px 4px 2px #ff6725,
        inset 0px -4px 4px #d43a12;
      border-radius: 10px;
      padding: 8px 16px;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .TitlePopup__contactUs {
    height: 90px;
    top: -45px;
  }

  .PopUpText__contactUs {
    padding-top: 73px;
  }
}

.PlayerMenuProfileBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 90px;
}

.PlayerMenuInfoBlock {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.PlayerImage {
  width: 40px;
  margin-right: 10px;
}

.MobilePlayerMenuContainer {
  margin-left: 10px;
  width: 100%;
}

@media screen and (max-width: 340px) {
  .PlayerMenuButton {
    display: none;
  }
}

@media screen and (min-width: 500px) {
  .MenuContainer {
    padding: 40px 35px 60px;
  }
}
