@import '@src/styles/colors.scss';

.Container {
  position: absolute;
  display: flex;
  flex: 0;
  align-items: center;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;

  background: #FDFBD3;
  border: 2px solid #C1A55C;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25), inset 0px -10px 4px #E9D7A7;
  border-radius: 15px;
  height: 40px;
  padding: 0 10px;
}

.Text {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  color: $chocolate;
}