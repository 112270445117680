@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.UnstakeInfoFillContainer {
  position: relative;
  background-color: #fff;
  height: 50px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.UnstakeInfoOuterContainer {
  position: relative;
  overflow: hidden;
  height: 95px;
  width: 100%;
}

.UnstakeInfoContainer {
  position: absolute;
  width: 100%;
  min-width: 260px;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  height: 95px;
}
.StakeNoInfoContainer {
  height: 65px;
}

.UnstakePolygon {
  width: 105%;
  position: absolute;
  height: 150px;
  bottom: 3px;
  left: -16px;
}

.UnstakeInfoTitle {
  position: relative;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  color: $green_pea3;
}

.InnerContainer {
  position: relative;
  padding: 48px 48px 32px;
  padding-top: 10px;
  &__stake {
    padding-top: 81px;
  }
}

.ValidatorsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.ValidatorsTitle {
  position: relative;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 24px;
  color: $green_pea3;
  margin-right: 16px;
}

.ValidatorConeIcon {
  width: 21.5px;
  height: 23.5px;
  bottom: 4px;
  position: relative;
}

.RadioButtonTitle {
  color: #246916AA !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  align-items: center;
  display: flex;
  &_selected {
    font-size: 22px !important;
    font-weight: 600 !important;
    align-items: center;
    display: flex;
    color: #246916 !important;
  }
}

.RadioButtonInnerCircle {
  background-color: #246916AA !important;
  &_selected {
    background-color: #246916 !important;
  }
}

.RadioButtonOuterCircle {
  border: 1px solid #246916AA !important;

  &_selected {
    border: 1px solid #246916 !important;
  }
}

.ButtonsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
  margin-top: 23px;
}

.TitleGreenButton {
  @include textBorder_4px_shadow($green_pea);

  &__android {
    @include textBorder_2px_shadow($green_pea);
  }
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow($chocolate);
  }
}

.Input_InputContainer {
  margin-top: 0px;
  background-color: $lawn_green;
  border-radius: 10px;
  width: 50%;
  box-shadow: inset 0px 4px 10px #61A023;
  border: 1px solid transparent !important;
}

.Input_Container {
  background-color: transparent;
  margin-top: 8px;
}
.Input_InvestContainer {
  display: flex;
  align-items: center;
  background-color: transparent;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
}

.Input_Input {
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $lime_light_green;
  height: 40px;

  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $lime_light_green;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.LabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;

  color: $dark_gray;
}

// *******************
// *******************

.StepValueSliderContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 0;
}

.StepValueSlider {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  color: $green_pea3;
}

.ModalInvestSlider {
  -webkit-appearance: none;
  margin-top: 30px;
  margin-bottom: 45px;
  width: 100%;
  height: 10px;
  background: $green_marsh;
  border-radius: 5px;
  background-image: linear-gradient(#ffd30b, #ff8e04);
  background-size: 1% 100%;
  background-repeat: no-repeat;
}

/* Input Thumb */
.ModalInvestSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 50px;
  height: 80px;
  cursor: ew-resize;
  background: no-repeat url('../../../assets/control/thumb_pine.png');
  background-size: 54px 59px;
  background-position: center 9px;
  box-shadow: none;
  border: none;
}

/* Input Track */
.ModalInvestSlider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

/*ms*/
.ModalInvestSlider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

.ModalInvestSlider::-ms-fill-lower {
  width: 100%;
  height: 10px;
  background: $green_marsh;
  border-radius: 5px;
  background-image: linear-gradient(#ffd30b, #ff8e04);
  background-size: 1% 100%;
  background-repeat: no-repeat;
}

.ModalInvestSlider::-ms-thumb {
  width: 50px;
  height: 80px;
  cursor: ew-resize;
  background: no-repeat url('../../../assets/control/thumb_pine.png');
  background-size: 54px 59px;
  background-position: center 9px;
  box-shadow: none;
  border: none;
}

.ModalInvestSlider::-ms-track {
  box-shadow: none;
  border: none;
  background: transparent;
}

.ModalInvestSlider:focus {
  outline: none;
}

@media screen and (max-width: 750px) {
  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
  }
  .ButtonContainer {
    height: 120px;
  }
}

// Mobile version Modal, after 718px
@media screen and (max-width: 1000px) { 
  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
    margin-top: 3px;
  }
  .ButtonContainer {
    height: 60px;
  }
  .ButtonContainer:first-child {
    margin-bottom: 0px;
  }

  .RadioButtonTitle {
    font-size: 14px;
    &_selected {
      font-size: 14px;
    }
  }

  .Input_Container {
    background-color: transparent;
  }
  .Input_InputContainer {
    flex: 0;
  }

  .Input_Input {
    background-color: transparent;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    height: 38px;

    &::placeholder {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
    }
  }
  .Input_InvestContainer {
    margin-bottom: 5px;
  }

  .LabelInput {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }

  .StepValueSliderContainer {
    position: absolute;
    top: 50px;
    left: 50px;
    right: 50px;
  }

  .StepValueSlider {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }

  .ModalInvestSlider {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  /* Input Thumb */
  .ModalInvestSlider::-webkit-slider-thumb {
    width: 30px;
    height: 60px;
    background-size: 34px 37px;
    background-position: center 9px;
  }
}

@media screen and (max-width: 1000px) {
  .ButtonContainer {
    height: 60px;
  }
  .StakeNoInfoContainer {
    height: 45px;
  }
  .UnstakeInfoOuterContainer {
    height: 50px;
  }
  .UnstakePolygon {
    bottom: auto;
    height: 100px;
    top: -50px;
  }
  .UnstakeInfoFillContainer {
    height: 25px;
  }
  
  .UnstakeInfoTitle {
    position: relative;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    top: 3px;
    text-align: center;
    color: $green_pea3;
  }
  .InnerContainer {
    padding: 48px 48px 4px;
    padding-top: 5px;
  }

  .Input_Input {
    height: 28px;
    &::placeholder {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 360px) {
  .ButtonContainer {
    height: 60px;
  }
}