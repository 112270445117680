@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.SubTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: $brown_dark;
  margin: 20px 20px 0;
}

.SeedPraseContainerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SeedPraseContainer {
  margin-top: 27px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: inset 0px 2px 0px #cccccc;
  border-radius: 12px;
  background-color: #eaeaea;
  padding: 11px 5px 0;
  min-width: 330px;
  max-width: 330px;
}

.CheckBoxContainer {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  &__selected {
    margin-bottom: 8px;
  }
}

.CheckBoxTextContainer {
  display: flex;
  flex-wrap: wrap;
  // width: 220px;
}

.CheckBoxTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #424242;
  align-self: center;
}

.ReferralContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.TitleEnterReferral {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #424242;
  margin-right: 16px;
}

.ImportantTitle {
  margin-bottom: 20px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: $cinnabar;

  &__description {
    color: $brown_dark;
  }
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

.Input_InputContainer {
  margin-top: 0px;
  background-color: $light_gray_EA;
  border-radius: 12px;
  box-shadow: inset 0px 4px 10px #bebebe;
  border: 1px solid transparent !important;
}

.WordInput_InputContainer {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  border-radius: unset;
  border: none;

  &_isSecret {
    filter: blur(4px);
  }
}

.WordInput_InputContainer:focus-within {
  border: none;
}

.Input_Container {
  background-color: transparent;
  padding: 0;
  max-width: 330px;
  min-width: 330px;

  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #676767;
  }
}

.WordInput_Input {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  border-radius: unset;
  box-shadow: unset;
  border-right: 1px solid #dadada;
  padding: 0 5px;
  margin-bottom: 10px;

  &__noRightBorder {
    border-right: none;
  }

  &::placeholder {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.LabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  color: $brown_dark;
  margin: 10px 0 10px;
}

.PaddingInnerContainer {
  padding: 0 55px;
}

.InputContainerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InputPasswordStretch {
  align-self: stretch;
}

.ButtonsContainer,
.ButtonContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.ButtonContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.CopyButtonContainer {
  margin-right: 10px;
}

.CopyButton {
  width: 120px;
}

.CopiedContainer {
  bottom: auto;
  top: 0;
}

.Input_InputContainer {
  margin-top: 0px;
  border-radius: 12px;
  border: 1px solid transparent !important;
  box-shadow: inset 0px 2px 0px #cccccc;
  background-color: #eaeaea;
}

.Input_Input {
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  height: 34px;

  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #676767;
  }
}

.ShowButtonDefaultIcon {
  width: 50px;
}

.CodeLabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: $dark_gray;
}

.CodeInput_InputContainer {
  margin: 0;
  background-color: #eeeeee;
  border-radius: 10px;
  box-shadow: inset 0px 2px 0px #cccccc;
  border: 1px solid #ffffff !important;
  flex: 0;
  width: 80px;
}

.CodeInput_Container {
  background-color: transparent;
  height: 100px;
}

.CodeInput_Input {
  box-shadow: unset;
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 38px;

  &::placeholder {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #676767;
  }
}

.GreenButtonShow {
  cursor: pointer;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .ButtonsContainer:first-child {
    margin-bottom: 30px;
  }

  .Input_Input {
    background-color: transparent;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    height: 34px;

    &::placeholder {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: $gray;
    }
  }
}

.ButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;

  &__Green {
    @include textBorder_2px_shadow(
      $green_pea
    );
  }

  &__Orange {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }

  &__CopyButton {
    font-size: 18px;
  }
}

.NextButton {
  width: 180px;
  margin: 0 10px;
}

.Button {
  width: 180px;
}

@media screen and (max-width: 500px) {
  .ButtonsContainer {
    flex-direction: column-reverse;
    align-items: center;
  }

  .NextButton {
    margin: 10px 0;
  }

  .PaddingInnerContainer {
    padding: 0;
  }

  .Input_Container {
    max-width: 250px;
    min-width: 250px;
  }

  .WordInput_Input {
    font-size: 14px;
    padding: 0;
  }

  .SeedPraseContainer {
    min-width: 250px;
    max-width: 250px;
  }
}
