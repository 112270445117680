@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // z-index: 1;
  overflow: hidden;
  height: 750px;
}

.BackgroundImagesContainer {
  display: flex;
}

.BackgroundMobileImage {
  width: 360px;
}

.BackgroundTablet {
  width: 1020px;
  display: none;
}

.BackgroundDesktopImage {
  width: 1920px;
  display: none;
}

.TitleContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;
  margin-top: 96px;
  z-index: 15;
}

.CaptionSubtitleContainer {
  margin-bottom: 20px;
}

.CaptionSubtitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: $white;
}

.InfoContainer {
  width: 100%;
  position: absolute;
  top: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.HowToPlayButton {
  width: 240px;
}

.TitleButton {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
  text-align: center;
  color: $white;
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

.SocialIconsContainer {
  display: none;
}

@media screen and (min-width: 359.99px) {
  .BackgroundMobileImage {
    display: none;
  }

  .BackgroundTablet {
    display: initial;
  }

  .BackgroundImagesContainer {
    width: 100%;
  }
}

@media screen and (min-width: 435px) {
  .CaptionSubtitleContainer {
    margin-bottom: 0;
  }
}

.ButtonContainer {
  margin-top: 60px;
}

@media screen and (min-width: 1000px) {
  .TitleContainer {
    margin-top: 177px;
  }

  .CaptionTitle {
    font-size: 60px;
  }

  .SocialIconsContainer {
    display: initial;
  }
}

@media screen and (min-width: 1019.99px) {
  .BackgroundTabletImage {
    width: 100%;
  }
}

@media screen and (min-width: 1300px) {
  .CaptionTitle {
    font-size: 100px;
    line-height: 100%;
  }

  .CaptionSubtitle {
    font-size: 20px;
    line-height: 120%;
  }
}

@media screen and (min-width: 1418px) {
  .BackgroundTabletImage {
    display: none;
  }

  .BackgroundDesktopImage {
    display: initial;
  }
}

@media screen and (min-width: 1919.99px) {
  .BackgroundDesktopImage {
    width: 100%;
  }
}
