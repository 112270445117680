@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.VestingSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Container {
  width: 100%;
  position: relative;
  z-index: 3;
  padding: 50px 20px 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.TitleContainer {
  margin-bottom: 20px;
}

.ButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -44px;
}

.ButtonContainer {
  width: 240px;
}

.WatchButton {
  width: 240px;
}

.TitleButton {
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

@media screen and (min-width: 799.99px) {
  .Container {
    justify-content: space-between;
    flex-direction: row;
  }

  .ButtonWrapper {
    position: relative;
    left: 0;
    top: 50%;
  }

  .Title {
    margin-bottom: 0;
    text-align: left;
    font-size: 33px;
  }

  .TitleContainer {
    margin-bottom: 0;
    margin-left: 10px;
  }

  .WatchButtonImage {
    margin-right: 25px;
  }
}

@media screen and (min-width: 949.99px) {
  .Container {
    padding: 55px 50px 55px 40px;
  }
}

@media screen and (min-width: 1020px) {
  .Container {
    width: 960px;
  }
}

@media screen and (min-width: 1300px) {
  .Title {
    font-size: 50px;
    line-height: 100%;
  }

  .Container {
    width: 1180px;
    padding: 75px 60px;
  }
}
