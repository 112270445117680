@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.NewsSection {
  position: relative;
  z-index: 2;
  background: #2C5120; // winter - #68B8E4; summer - #2c5120;
  border-radius: 20px;
  top: -50px;
}

.NewsItem {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  max-width: 420px;
  animation: fade-in 1s linear;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.NewsItem:last-child {
  margin-bottom: 0px;
}

.NewsItemText {
  margin-top: 20px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: white;
  -webkit-text-stroke: unset;
}

.NewsItemImageContainer {
  position: relative;
  width: 420px;
  height: 310px;
  border-radius: 20px;
  box-shadow: 0px 10px 15px
    rgba(0, 0, 0, 0.25);
}

.NewsItemImage {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.NewsItemImageHover {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 43.17%,
    rgb(0, 0, 0, 0.8) 82.58%
  );
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.NewsItemSocialImage {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 6px;
}

.NewsItems {
  display: flex;
  justify-content: center;
  padding-bottom: 70px;
  margin-left: 70px;
  margin-right: 70px;
  flex-wrap: wrap;
  transition: all 0.5s;
}

.KeyNewsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  padding-top: 30px;
  margin-bottom: 20px;
}

.SledIcon {
  position: absolute;
  left: 0;
  top: 0;
}

@media screen and (max-width: 1100px) {
  .NewsItem {
    max-width: 350px;
  }

  .NewsItemImageContainer {
    width: 350px;
    height: 258.3px;
  }

  .NewsItems {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .NewsItem {
    max-width: 320px;
  }

  .NewsItemImageContainer {
    width: 320px;
    height: 236.2px;
  }

  .NewsItemText {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
}
