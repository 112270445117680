@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.BenefitsSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.BenefitsSection {
  max-width: 1200px;
  position: relative;
  z-index: 3;
  padding: 30px 10px 40px;
}

.SnowLine {
  display: none;
}

.SnowTabletLine {
  display: block;
  position: absolute;
  top: -45px;
  width: 100vw;
  max-height: 80px;
  z-index: 2;
}

.LightLeft {
  display: none;
  // display: block;
  // position: absolute;
  // top: -80px;
  // left: 0;
}
.LightRight {
  display: none;
  // display: block;
  // position: absolute;
  // top: -120px;
  // right: 0;
}

.LightLeftMobile {
  // display: none;
  display: block;
  position: absolute;
  top: -35px;
  left: 0;
}
.LightRightMobile {
  // display: none;
  display: block;
  position: absolute;
  top: -30px;
  right: 0;
}

/* .SubtitleContainer {
  max-width: 340px;
  align-items: flex-start;
} */

/* .Subtitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  color: $white;
  text-align: left;
  padding: unset;
} */

.BenefitsItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4px;
}

.BenefitItem {
  width: 150px;
  margin: 14px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.BenefitTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: $white;
  margin-bottom: 10px;
}

.BenefitText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: $light_green_mobile;
}

.BenefitItemImage {
  width: 44px;
  height: 44px;
  margin-bottom: 9px;
}

.EventCountDown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.EventCountDownText {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  color: $white;
  @include textBorder_2px($brown_dark);
}

@media screen and (min-width: 499.99px) {
  .BenefitsItems {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 649.99px) {
  .BenefitItem {
    margin: 15px;
  }
}

@media screen and (min-width: 735px) and (max-width: 950px) {
  .BenefitsItems {
    max-width: 700px;
  }
}

@media screen and (min-width: 1000px) {
  .BenefitTitle {
    font-size: 20px;
  }

  .BenefitText {
    font-size: 16px;
  }

  .BenefitsItems {
    max-width: 950px;
  }

  .BenefitItem {
    width: 220px;
    margin: 25px;
  }
  .SnowTabletLine {
    display: none;
  }
  .SnowLine {
    display: block;
    position: absolute;
    top: -64px;
    width: 100vw;
    max-height: 80px;
    z-index: 2;
  }
  .LightLeft {
    display: block;
    position: absolute;
    top: -110px;
    left: 0;
  }
  .LightRight {
    display: block;
    position: absolute;
    top: -150px;
    right: 0;
  }
  .LightLeftMobile, .LightRightMobile {
    display: none;
  }
}

@media screen and (min-width: 1300px) {
  .BenefitsSection {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .BenefitItem {
    margin: 10px;
  }

  .BenefitItem:first-child {
    margin-left: 0;
  }

  .BenefitItem:last-child {
    margin-right: 0;
  }

  .Subtitle {
    width: 340px;
  }

  .BenefitsItems {
    max-width: unset;
  }

  .SnowLine {
    top: -50px;
  }
  .LightLeft {
    display: block;
    position: absolute;
    top: -80px;
    left: 0;
  }
  .LightRight {
    display: block;
    position: absolute;
    top: -120px;
    right: 0;
  }
  
}

@media screen and (max-width: 500px) {
  .LightLeftMobile {
    top: -45px;
  }
  .LightRightMobile {
    top: -40px
  }
}

@media screen and (max-width: 400px) {
  .LightLeftMobile {
    top: calc(-45px - (400px - 100vw)*0.1);
  }
  .LightRightMobile {
    top: calc(-40px - (400px - 100vw)*0.1);
  }
}