@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.PlayToEarnBannerContainer {
  position: relative;
  padding: 0 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InfoContainer {
  position: absolute;
  display: flex;
  align-items: center;
  width: 1000px;
}

.Title {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 100%;
  text-align: left;
  color: #ffffff;
}

.LogoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TitleLink {
  cursor: pointer;
  margin-left: 80px;
  margin-right: 140px;
}

.HoneywoodLogo {
  width: 130px;
  margin-right: 32px;
}

.Plus {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 33.75px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.PlayEarnLogoLink {
  margin-left: 32px;
}

.PlayEarnLogo {
  width: 160px;
}

.HoneywoodBannerTablet {
  width: 1000px;
  position: relative;
  top: 10px;
}

.HoneywoodBannerDesktop {
  width: 1220px;
  position: relative;
  top: 10px;
  display: none;
}

@media screen and (min-width: 1260px) {
  .HoneywoodBannerTablet {
    display: none;
  }

  .HoneywoodBannerDesktop {
    display: initial;
  }
  
  .InfoContainer {
    width: 1220px;
  }
}
