@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.BackgroundImagesContainer {
  position: fixed;
  display: flex;

  &__position {
    &_absolute {
      display: none;
    }
  }
}

.BackgroundMobileImage {
  width: 360px;
}

.BackgroundTabletImage {
  width: 1020px;
  display: none;
}

.BackgroundDesktopImage {
  width: 1920px;
  display: none;
}

.TitleContainer {
  padding: 0 20px;
  margin-top: 160px;
  margin-bottom: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.SecondTitleContainer {
  padding: 0 20px;
  margin-bottom: 60px;
}

.InfoContainer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.AboutHoneyWoodSubtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.BlockchainAndEcosystemSubtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.AboutHoneyWoodSecondSubtitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: $white;
  margin-top: 20px;
  max-width: 590px;
}

.LogosContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 195px;
}

.CosmosLogo {
  width: 136px;
  margin-bottom: 20px;
}

.TendermintLogo {
  width: 140px;
}

.CardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
  margin-bottom: 90px;
}

.CardBlock {
  width: 150px;
  height: 180px;
  background: $cod_gray;
  border-radius: 10px;
  margin: 10px;
}

.CardWrapper {
  position: relative;
  top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10px 10px;
}

.CardIcon {
  width: 80px;
  position: relative;
  margin-bottom: 10px;

  &__FlagIcon {
    right: -22px;
  }

  &__TreeIcon {
    width: 87px;
    right: -8px;
    margin-bottom: 0;
  }

  &__ApiaryIcon {
    width: 65px;
    right: -6px;
  }

  &__SaviourStatueIcon {
    right: -9px;
  }
}

.CardTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: $white;
  margin-bottom: 10px;
}

.CardSubtitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: $silver_chalice;
}

@media screen and (min-width: 359.99px) {
  .BackgroundMobileImage {
    display: none;
  }

  .BackgroundTabletImage {
    display: initial;
  }
}

@media screen and (min-width: 500px) {
  .CardContainer {
    margin-bottom: 120px;
  }

  .LogosContainer {
    flex-direction: row;
    margin-bottom: 95px;
  }

  .CosmosLogo {
    margin-bottom: 0;
    margin-right: 70px;
  }

  .TitleContainer {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1019.99px) {
  .BackgroundTabletImage {
    display: none;
  }

  .BackgroundDesktopImage {
    display: initial;
  }

  .TitleContainer {
    margin-top: 285px;
  }

  .AboutHoneyWoodTitle {
    font-size: 60px;
  }

  .AboutHoneyWoodSubtitle {
    max-width: 590px;
  }

  .BlockchainAndEcosystemSubtitle {
    max-width: 590px;
  }
}

@media screen and (min-width: 1300px) {
  .TitleContainer {
    max-width: 942px;
  }

  .AboutHoneyWoodTitle {
    font-size: 80px;
    margin-bottom: 30px;
  }

  .AboutHoneyWoodSubtitle {
    font-size: 20px;
    line-height: 120%;
  }

  .AboutHoneyWoodSecondSubtitle {
    font-size: 16px;
  }

  .TitleContainer {
    margin-bottom: 60px;
  }

  .LogosContainer {
    margin-bottom: 150px;
  }

  .BlockchainAndEcosystemTitle {
    font-size: 45px;
  }
}

@media screen and (min-width: 1919.99px) {
  .BackgroundDesktopImage {
    width: 100%;
  }

  .BackgroundImagesContainer {
    width: 100%; // makes the image view from the beginning
  }
}
