.SocialContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.SocialLink {
  margin-right: 20px;
}
// .SocialLink:first-child {
//   margin-left: 0px;
// }
.SocialLink:last-child {
  margin-right: 0px;
}

@media screen and (max-width: 1000px) {
  .SocialContainer {
    margin-left: 0px;
    justify-content: center;
  }
  .MobileSocialIcon {
    width: 32px;
  }
  .SocialLink {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  // .SocialLink:first-child {
  //   margin-right: 10px;
  //   margin-left: 10px;
  // }
  .SocialLink:last-child {
    margin-right: 0;
    // margin-left: 10px;
  }
}
@media screen and (max-width: 500px) {
  .SocialLink {
    margin-right: 10px;
    margin-bottom: 10px;
  }
 /*  .SocialLink:first-child {
    margin-left: 0;
    margin-right: 0;
  } */
  .SocialLink:last-child {
    margin-right: 0px;
    margin-bottom: 0px;
  }
}
