@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.ModalContainer {
  background-color: rgba($black, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  display: flex;
  overflow: auto;
  padding: 43px 10px 10px;
}

.Modal {
  margin: auto;
  position: relative;
  width: 718px;
  background: $white;
  border-radius: 40px;
  padding: 48px 48px 32px;
  padding-top: 111px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25), inset 0px -3px 6px rgba(255, 255, 255, 0.5), inset 0px -6px 6px rgba(0, 0, 0, 0.25);
  transition: all 0.5s;
}

.TitleModal {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -40px;
  display: flex;
  height: 80px;
  width: 512px;
}

.ButtonsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
  margin-top: 23px;
}

.ModalClose {
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: -30px;
  right: -30px;
  cursor: pointer;
}

.TitleGreenButton {
  @include textBorder_2px_shadow($green_pea);

  &__android {
    @include textBorder_2px_shadow($green_pea);
  }
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow($chocolate);
  }
}

.Input_InputContainer {
  margin-top: 0px;
  background-color: $light_gray_EA;
  border-radius: 15px;
  box-shadow: inset 0px 4px 10px #bebebe;
  border: 1px solid transparent !important;
}

.Input_Container {
  background-color: transparent;
  height: 140px;
  margin-top: 8px;
}

.Input_Input {
  background-color: transparent;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  height: 70px;
  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: $gray;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.LabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;

  color: $dark_gray;
}

@media screen and (max-width: 750px) {
  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
  }
  .ButtonContainer {
    height: 120px;
  }
}

// Mobile version Modal, after 718px
@media screen and (max-width: 1000px) { 
  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
    margin-top: 3px;
  }
  .ButtonContainer {
    height: 60px;
  }
  .ButtonContainer:first-child {
    margin-bottom: 0px;
  }

  .Input_Container {
    background-color: transparent;
    height: 60px;
    min-height: 90px;
  }
  .Input_InputContainer {
    flex: 0;
  }

  .Input_Input {
    background-color: transparent;
  
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
  
    height: 36px;
    &::placeholder {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
  
      color: $gray;
    }
  }

  .LabelInput {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 360px) {
  .ButtonContainer {
    height: 90px;
  }
}