.Container {
  position: relative;
  width: 100%;
  height: 100%;
}

.ModalClose {
  position: absolute;
  width: 80px;
  height: 80px;
  right: -35px;
  top: -35px;
  // right: calc((100vw - 700px) / 2 - 60px);
  background-color: transparent;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  &_stepper {
    top: 80px;
  }
}

@media screen and (max-width: 800px) {
  .ModalClose {
    position: absolute;
    width: 80px;
    height: 80px;
    // right: -10px;
    background-color: transparent;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 530px) {
  .ModalClose {
    position: absolute;
    width: 80px;
    height: 80px;
    // right: -15px;
    // top: -15px;
    background-color: transparent;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
}