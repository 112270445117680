@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.PopUpContainer {
  background-color: rgba($black, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
  top: 0;
  display: flex;
  overflow: auto;
}

.PopUp {
  margin: auto;
  position: relative;
  width: 320px;
  transition: all 0.5s;
}

.TitlePopup {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -40px;
  display: flex;
  height: 80px;
  width: 546px;
}

.PopUpClose {
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: -40px;
  right: -40px;
  cursor: pointer;
}

.PopUpTitle {
  @include title;
  text-transform: uppercase;
  font-size: 30px;
  padding-bottom: 21px;
  transition: all 0.5s;
}

.PopUpTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 10px 10px;
  padding: 50px 40px 40px;
}

.PopUpTitleTextHtmlText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: $brown_dark;
}
.ButtonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px; // 60 according to the layout
}
.ButtonContainer {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.TitleButton {
  &__GREEN {
    @include textBorder_1px_shadow($green_pea);
    &__android {
      @include textBorder_1px_shadow($green_pea);
    }
  }

  &__ORANGE {
    @include textBorder_1px_shadow($chocolate);
    &__android {
      @include textBorder_1px_shadow($chocolate);
    }
  }
}

.PopUpContainer {
  padding: 40px 10px 10px;
  overflow-x: hidden;
}

.PopUpClose {
  top: -14px;
  right: -13px;
  & img {
    width: 36px;
    height: 36px;
  }
}

.TitlePopup {
  top: -30px;
  height: 60px;
  width: 240px;
}

.Title {
  background: $atlantis;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 100%;
  color: $brown_dark;
  text-align: center;
  padding: 14px 30px;
}

.PopUpText {
  padding-top: 58px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 90px;
  color: $chocolate;
}

.HoneyIcon {
  width: 24px;
  height: auto;
}

@media screen and (min-width: 700px) {
  .PopUp {
    margin: auto;
    position: relative;
    width: 620px;
    transition: all 0.5s;
  }
  .PopUpTextContainer {
    padding: 50px 80px 40px;
  }
}
@media screen and (max-width: 600px) {
  .TitlePopup__contactUs {
    height: 90px;
    top: -45px;
  }

  .PopUpText__contactUs {
    padding-top: 73px;
  }
}

@media screen and (max-width: 500px) {
  .ButtonsContainer {
    flex-direction: column;
  }
  .ButtonContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }
}
