@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.ModalContainer {
  background: rgba(46, 29, 85, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  display: flex;
  overflow: auto;
  padding: 43px 10px 10px;

  flex-direction: column;
  align-items: center;
  // justify-content: center;
}

.ModalContainerInner {
  margin: auto;
  position: relative;
}

.Modal {
  // margin: auto;
  position: relative;
  width: 718px;
  border-radius: 40px;
  padding: 48px 48px 32px;
  background: #7151AA;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25), inset 0px -5px 5px 4px #5B2B8F;
  transition: all 0.5s;
}

.ModalDescription {
  margin-top: 12px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.CheckBoxContainer {
  display: flex;
}

.CheckBoxImage {
  width: 50px;
  height: 50px;
}

.CheckBox {
  margin-bottom: 16px;
}

.TitleModal {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -40px;
  display: flex;
  height: 80px;
  width: 512px;
  background: #534845;
  box-shadow: 0px 6.72px 10.08px rgba(0, 0, 0, 0.1), inset 0px -9px 3.36px #473C39;
}

.ButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
  margin-top: 23px;
  position: relative;
}

.CopyLinkButtonContainer {
  height: 68px;
}

.Title {
  color: #FFA40A;
  text-shadow:  0px 3px 0px #2C2C2C;
}

.ModalClose {
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: -30px;
  right: -30px;
  cursor: pointer;
  z-index: 2;
}

.TitleGreenButton {
  font-size: 30px;
  line-height: 28px;
  text-align: left;
  @include textBorder_2px_shadow($green_pea);
  &__android {
    font-size: 26px;
    line-height: 28px;
    @include textBorder_2px_shadow($green_pea);
  }
}

.TitleOrangeButton {
  // font-size: 30px;
  // line-height: 28px;
  // text-align: left;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow($chocolate);
  }
}

.Input_InputContainer {
  margin: 0;
  background-color: $light_gray_EA;
  border-radius: 15px;
  box-shadow: inset 0px 4px 10px #bebebe;
  border: 1px solid transparent !important;
}

.Input_Container {
  background-color: transparent;
  margin-top: 0;
}
.Input_InvestContainer {
  background-color: transparent;
  margin-bottom: 15px;
}

.Input_Input {
  background-color: transparent;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  height: 40px;
  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: $gray;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.LabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;

  color: $dark_gray;
}

@media screen and (max-width: 750px) {
  .ModalContainer {
    position: fixed;
    padding: 10px;
    padding-top: 50px;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    display: flex;
    overflow: auto;
    overflow-x: hidden;
  }
  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
  }
}

.ChooseSinglePackButton {
  margin-top: 4px;
}
.TitleText {
  font-family: Fira Sans;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  // display: flex;
  // align-items: center;
  text-align: center;
}
.CostContainer {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.TitleNewTotalText {
  font-family: Fira Sans;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  // margin-bottom: 16px;
  color: $red;
}
.TitleOldTotalTextContainer {
  margin-left: 8px;
  position: relative;
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TitleOldTotalLine {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 0;
}
.TitleOldTotalText {
  display: flex;
  flex: 1;
  white-space: pre;

  font-family: Fira Sans;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  // margin-bottom: 16px;
  color: $gray;
}
.ReferralContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.TitleEnterReferral {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: $brown_dark;
  margin-right: 16px;
}
.PackItemsContainer {
  display: flex;
  padding: 40px 10px 50px;
  flex-wrap: nowrap;
  gap: 80px;
  justify-content: space-around;
}
.ItemsBlock {
  display: flex;
  padding: 10px;
}
.PaymentsButtonContainer {
  display: flex;
  justify-content: center;
  flex: 1;
  height: 120px;
  text-align: center;
}
.PaymentButtonContainer {
  display: flex;
  flex: 1;
  max-width: 200px;
  cursor: pointer;
}
.PaymentInfoContainer {
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  flex: 1;
  width: 100%;
}
.TitleButton {
  flex: 1;
  text-align: center;
}
.PaymentInfoTitle {
  margin-bottom: 8px;
  word-break: break-word;
  text-align: center;

  font-family: Fira Sans;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  // display: flex;
  align-items: center;
}
.PaymentInfoDescription {
  display: flex;
  margin-bottom: 8px;
  word-break: break-word;

  font-family: Fira Sans;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
}
.PaymentInfoLink {
  font-family: Fira Sans;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  text-decoration: underline !important;
  word-break: break-word;
  color: $blue_sea;
}
.PaymentInfoAddress {
  font-family: Fira Sans;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  // text-decoration: underline !important;
  word-break: break-word;
  text-align: center;
  color: $blue_sea;
}
.PaymentInfoAttentionContainer {
  border-radius: 20px;
  // padding-top: 21px;
  // padding-bottom: 19px;
  padding: 21px 30px 19px;
}
.PaymentInfoAttention {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $red;
}
.TitleTotalNowPaymentText {
  margin-bottom: 16px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: $red;
  text-align: center;
}
.BinanceQrCode {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 300px;
  height: 300px;
}
.NowPaymentQrCode {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  margin: 20px 0;
}
.InformationPopupCopied {
  top: 0;
  bottom: auto;
}

.LoadingTransferringPackContainer {
  position: relative;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CheckBoxContainer {
  display: flex;
  margin-top: 32px;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.CheckBoxImage {
  width: 30px;
  height: 30px;
}

.CheckBoxTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-right: 10px;
}

.PrivacyAndTermsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RightsText {
  text-align: center;

  font-family: Fira Sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: $brown_dark;
  display: flex;
  align-self: center;
}
.PaymentsText {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: $light_green;
  text-decoration: underline;
  cursor: pointer;
}
a.PrivacyLink {
  margin-right: 5px;
  text-decoration: underline;
}
a.TermsLink {
  margin-right: 5px;
  text-decoration: underline;
}

a.CoinSaleLink {
  text-decoration: underline;
}

.TitleContainer {
  height: 100%;
}
.SmallTitleContainer {
  height: 100px;
}
.SmallTitleOldTotalUSDTText {
  line-height: 120%;
  &_650 {
    font-size: 20px;
  }
  &_375 {
    font-size: 14px;
  }
}
.SmallPriceDiscountUSDTText {
  line-height: 120%;
  &_650 {
    font-size: 24px;
  }
  &_375 {
    font-size: 18px;
  }
}

// Mobile version Modal, after 718px
@media screen and (max-width: 1000px) {
  .PackItemsContainer {
    gap: 12vw;
  }
  .Modal {
    margin: auto;
    position: relative;
    width: calc(100vw - 30px);
    border-radius: 40px;
    padding: 48px 32px 32px;
    transition: all 0.5s;
  }
  .ModalContainer {
    overflow-x: hidden;
  }
  .ModalClose {
    top: -40px;
    right: -25px;
    & img {
      width: 70px;
      height: 70px;
    }
  }
  .TitleModal {
    top: -40px;
    height: 90px;
    width: 240px;
  }
  .Title {
    white-space: nowrap;
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
  }
  .InfoTitle {
    margin-bottom: 40px;
  }

  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
    margin-top: 3px;
  }

  .Input_Container {
    background-color: transparent;
    height: 130px;
  }
  .Input_InputContainer {
    flex: 0;
  }

  .Input_Input {
    background-color: transparent;

    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    height: 48px;
    &::placeholder {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;

      color: $gray;
    }
  }

  .LabelInput {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 650px) {
  .ItemsBlock {
    display: flex;
    padding: 4px 10px;
    width: 100%;
  }
  .PackItemsContainer {
    padding: 40px 6px;
    flex-wrap: wrap;
    gap: 20px;
  }
}

@media screen and (max-width: 600px) {
  a.TermsLink {
    margin-right: 5px;
    text-decoration: underline;
  }

  a.CoinSaleLink {
    padding-top: 8px;
    padding-left: 50px;
  }
}

@media screen and (max-width: 500px) {
  .CostContainer {
    flex-direction: column;
  }
  .TitleOldTotalText {
    text-align: center;
    display: block;
  }
}

@media screen and (max-width: 450px) {
  a.PrivacyLink {
    padding-top: 8px;
    padding-left: 50px;
  }

  a.CoinSaleLink {
    padding-top: 8px;
    padding-left: 0px;
  }
  .ReferralContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .TitleEnterReferral {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $brown_dark;
    margin-right: 0px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 430px) {
  a.TermsLink {
    padding-top: 8px;
    padding-left: 50px;
    padding-bottom: 8px;
  }
  a.PrivacyLink {
    padding-top: 8px;
    padding-left: 50px;
    padding-bottom: 8px;
  }
  a.CoinSaleLink {
    padding-top: 8px;
    padding-left: 50px;
  }
  .PackItemsContainer {
    padding: 0 0 20px;
  }
}

@media screen and (max-width: 400px) {
  .Title {
    white-space: pre-wrap;
  }

  .PaymentsButtonContainer {
    height: 100px;
  }
}