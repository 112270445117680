@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.ParticipateSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
}

.Container {
  width: 100%;
  z-index: 4;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: $dark_green_mobile;
  border-radius: 20px;
  padding: 40px 20px 70px;
}

.TitleContainer {
  margin-bottom: 10px;
}

.ButtonWrapper {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ButtonContainer {
  width: 260px;
}

.ParticipateButtonContainer {
  margin-bottom: 20px;
}

.Button {
  width: 260px;
}

.TitleButton {
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
}

.TitleGreenButton {
  @include textBorder_2px_shadow(
    $light_green_button_text
  );

  &__android {
    @include textBorder_2px_shadow(
      $light_green_button_text
    );
  }
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

.ParticipateImage {
  margin-bottom: 10px;
}

@media screen and (min-width: 799.99px) {
  .Container {
    justify-content: space-between;
    flex-direction: row;
  }

  .ButtonWrapper {
    position: relative;
    left: -45px;
    top: 50%;
  }

  .Title {
    margin-bottom: 0;
    text-align: left;
    font-size: 33px;
  }

  .TitleContainer {
    margin-bottom: 0;
    margin-left: 10px;
  }

  .ParticipateImage {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 849.99px) {
  .ButtonWrapper {
    left: -25px;
  }
}

@media screen and (min-width: 949.99px) {
  .ButtonWrapper {
    left: calc(100vw - 995px);
  }

  .Container {
    padding: 55px 50px 55px 40px;
  }
}

@media screen and (min-width: 1000px) {
  .ButtonWrapper {
    left: unset;
  }
}

@media screen and (min-width: 1020px) {
  .Container {
    width: 960px;
  }
}

@media screen and (min-width: 1300px) {
  .Title {
    font-size: 50px;
    line-height: 100%;
  }

  .Container {
    width: 1180px;
    padding: 40px 60px;
  }
}
