@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;800;900&display=swap');
@import '@src/styles/reset.css';

body,
html {
  font-family: 'Open Sans';
  font-size: 10px;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  /* winter - #68B8E4; summer - #2C5120; ...?#bfd447*/
  background-color: #2C5120; 
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow-x: hidden;
  min-width: auto;
}

body > iframe {
  display: none;
}

html {
  height: -webkit-fill-available;
}

button:focus {
  outline: 0;
}

.main {
  width: 100%;
}

* {
  box-sizing: border-box;
}

a:-webkit-any-link {
  text-decoration: none;
}

body > iframe {
  display: none;
}

a[href^='tel'] {
  color: inherit; /* Inherit text color of parent element. */
  text-decoration: none; /* Remove underline. */
  /* Additional css 'propery: value;' pairs here */
}
