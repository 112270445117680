@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.PlayToEarnBannerContainer {
  position: relative;
  padding: 0 20px 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.HoneywoodBannerMobile {
  width: 371px;
}

.InfoContainer {
  position: absolute;
  top: 60px;
}

.TitleLink {
  cursor: pointer;
}

.Title {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 33.75px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 44px;
}

.LogoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HoneywoodLogo {
  width: 89px;
  margin-right: 20px;
}

.Plus {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 33.75px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.PlayEarnLogo {
  width: 109px;
  margin-left: 20px;
}
