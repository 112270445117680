@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.ItemContainer {
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.ItemTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ItemDescriptionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  &__noHeight {
    height: unset;
  }
}
.ItemTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  margin: 16px 0;
  color: #FFFFFF;
  @include textBorder_2px_shadow(#731386);
  z-index: 1;
}
.ItemDescription {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  z-index: 1;
}
.ItemPrice {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  z-index: 1;
  @include textBorder_2px_shadow(#731386);
  &__Free {
    color: #ffb800;
    @include textBorder_2px_shadow($chocolate);
    &_mobile {
      font-size: 20px;
      line-height: 18px;
    }
  }
}

.Icon {
  display: flex;
  width: 100%;
  z-index: 1;
}

.BlessIcon {
  position: absolute;
  width: 35vw;
  max-width: 380px;
  transform: translate(-30%, 0)
}

.ItemBlock {
  display: flex;
  justify-content: center;
}
.IconContainer {
  display: flex;
  position: relative;
  align-items: center;
}
.ItemTextContainer {
  display: flex;
  flex-direction: column;
  &__height {
    height: 100%;
    justify-content: center;
  }
}
.PriceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

@media screen and (max-width: 1000px) {
  .BlessIcon {
    max-width: unset;
    width: 50vw;
  }
}
@media screen and (max-width: 650px) {
  .ItemContainer {
    min-height: 88px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  .IconContainer {
    height: unset;
  }
  .Icon {
    width: 65px;
  }
  .ItemTextContainer {
    min-width: 156px;
    &__height {
      height: unset;
      margin-bottom: 0;
    }
  }
  .ItemTitleContainer {
    justify-content: flex-start;
    height: unset;
    min-width: 125px;
  }
  .ItemTitle {
    font-size: 19px;
    line-height: 23px;
    text-align: left;
    height: unset;
  }
  .ItemDescriptionContainer {
    justify-content: center;
  }
  .ItemPrice {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    &__Free {
      color: #ffb800;
      @include textBorder_2px_shadow($chocolate);
      &_mobile {
        font-size: 20px;
        line-height: 18px;
      }
    }
  }
  .ItemPrice__Free {
    font-size: 20px;
    line-height: 18px;
    text-align: left;
  }
  .BlessIcon {
    max-width: 200px;
  }
}
// .TetherUsdtWhiteIcon {
//   width: 25px;
//   position: relative;
//   top: 1px;
//   &__Display {
//     &_none {
//       display: none;
//     }
//   }
// }

@media screen and (max-width: 430px) {
  .ItemContainer {
    min-width: unset;
    flex-flow: column;
    // display: flex;
  }
  .ItemTitleContainer {
    justify-content: flex-start;
    height: unset;
    min-width: unset;
  }
  .PriceContainer {
    display: flex;
    width: 62px;
    white-space: nowrap;
  }
  .IconContainer {
    height: unset;
  }
  .ItemTextContainer {
    min-width: 130px;
  }
}
