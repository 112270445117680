@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.FooterContainer {
  position: relative;
  overflow: hidden;
  z-index: 5;
  padding: 40px 20px 200px;
  background-color: $green_mobile;

  &_isAccount {
    background-color: $packs_title_dark_green_background; // winter - #68B8E4; summer - $packs_title_dark_green_background;
  }
}

.InfoContainer {
  position: relative;
  z-index: 8;
}

.LeftBottomImageMobile {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 197px;
}

.RightBottomImageMobile {
  position: absolute;
  bottom: 0;
  right: 0px; // winter - -140px; summer - 0px;
  width: 316px;
}

.LeftBottomImageTablet {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 260px;
  display: none;
}

.RightBottomImageTablet {
  position: absolute;
  bottom: 0;
  right: 0px; // winter - -140px; summer - 0px;
  width: 450px;
  display: none;
}

.LeftBottomImageDesktop {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 500px;
  // winter - right: -250px;
  display: none;
}

.RightBottomImageDesktop {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 800px;
  // winter - right: -250px;
  display: none;
}

.MailLink {
  color: $yellow_green;
  text-decoration: none; // for Mozilla
}

.MailText {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: $yellow_green;
  text-decoration: underline;
}

.SocialContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  z-index: 2;
}

.RightsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.PrivacyAndTermsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RightsText {
  position: relative;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $light_green; // winter - #2188C0; summer - $light_green;
}

a.PrivacyLink {
  margin-right: 5px;
  text-decoration: underline;
}

a.TermsLink {
  text-decoration: underline;
}

.MailContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.ContactUsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  color: $light_green; // winter - #2188C0; summer - $light_green;
  margin-bottom: 15px;
}

.ContactUsText {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: $light_green; // winter - #2188C0; summer - $light_green;
  cursor: pointer;
}

.ButtonLinks {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 40px;
}

.MenuContainer {
  transition: all 0.5s;

  &__item {
    position: relative;
    text-decoration: none; // for Mozilla
    margin-bottom: 15px;

    &_SellingPack {
      background: #fb4517;
      box-shadow: 0px 3px 5px
          rgba(0, 0, 0, 0.25),
        inset 0px 4px 2px #ff6725,
        inset 0px -4px 4px #d43a12;
      border-radius: 10px;
    }
  }

  &__item:active {
    background: #b93b1b;
    box-shadow: 0px 3px 5px
        rgba(0, 0, 0, 0.25),
      inset 0px 4px 2px #ff6725,
      inset 0px -4px 4px #d43a12;
    border-radius: 10px;
  }

  &__title {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    padding: 8px 16px;
    text-align: center;
    color: $white;
  }
}

@media (hover: hover) {
  .MenuContainer__item:hover {
    background: #fb4517;
    box-shadow: 0px 3px 5px
        rgba(0, 0, 0, 0.25),
      inset 0px 4px 2px #ff6725,
      inset 0px -4px 4px #d43a12;
    border-radius: 10px;
    cursor: pointer;
  }

  .MenuContainer__item:active {
    background: #b93b1b;
    box-shadow: 0px 3px 5px
        rgba(0, 0, 0, 0.25),
      inset 0px 4px 2px #ff6725,
      inset 0px -4px 4px #d43a12;
    border-radius: 10px;
    cursor: pointer;
  }
}

.HoneyWoodLogo {
  width: 121px;
  margin-bottom: 36px;
}

@media screen and (min-width: 450px) {
  .ButtonLinks {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

@media screen and (min-width: 550px) {
  .FooterContainer {
    padding: 40px 20px 140px;
  }
}

@media screen and (min-width: 600px) {
  .LeftBottomImageMobile {
    display: none;
  }

  .RightBottomImageMobile {
    display: none;
  }

  .LeftBottomImageTablet {
    display: initial;
  }

  .RightBottomImageTablet {
    display: initial;
  }

  .FooterContainer {
    padding: 40px 20px 160px;
  }
}

@media screen and (min-width: 650px) {
  .LeftBottomImageTablet {
    width: 300px;
  }

  .RightBottomImageTablet {
    width: 500px;
    // winter - right: -250px;
  }

  .FooterContainer {
    padding: 40px 20px 180px;
  }
}

@media screen and (min-width: 750px) {
  .LeftBottomImageTablet {
    width: 350px;
  }

  .RightBottomImageTablet {
    width: 600px;
    // winter - right: -250px;
  }

  .FooterContainer {
    padding: 40px 20px 220px;
  }
}

@media screen and (min-width: 850px) {
  .LeftBottomImageTablet {
    width: 400px;
  }

  .RightBottomImageTablet {
    width: 700px;
    // winter - right: -250px;
  }

  .FooterContainer {
    padding: 40px 20px 230px;
  }
}

@media screen and (min-width: 1000px) {
  .LeftBottomImageTablet {
    width: 463px;
  }

  .RightBottomImageTablet {
    width: 780px;
    // winter - right: -250px;
  }
}

@media screen and (min-width: 1100px) {
  .FooterContainer {
    padding-bottom: 150px;
  }
}

@media screen and (min-width: 1400px) {
  .LeftBottomImageTablet {
    display: none;
  }

  .RightBottomImageTablet {
    display: none;
  }

  .LeftBottomImageDesktop {
    display: initial;
  }

  .RightBottomImageDesktop {
    display: initial;
  }
}

@media screen and (min-width: 1600px) {
  .LeftBottomImageDesktop {
    width: 595px;
  }

  .RightBottomImageDesktop {
    width: 939px;
  }

  .FooterContainer {
    padding-bottom: 180px;
  }
}
