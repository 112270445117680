@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.CookiesManageModalWrapper {
  margin-top: 25px;
  display: flex;
}

.CookiesManageModalContainer {
  position: relative;
  z-index: 9;
  margin-top: auto;
  margin-bottom: auto;
  background: $woodland;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25),
    inset 0px -3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: all 0.5s;
  max-width: 673px;
  padding: 36px 48px 0;
  flex-direction: column;
}

.CookiesManageModalClose {
  display: flex;
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: -12px;
  right: -12px;
  cursor: pointer;
}

.CookiesManageModalItem {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 15px;
}

.CheckBoxTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: $wild_sand;
  padding: 0 5px;
}

.CookiesItemText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: $wild_sand;
  text-align: left;
  padding-left: 35px;
}

.ButtonsContainer {
  padding: 0;
}

.ButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;

  &__Green {
    @include textBorder_2px_shadow(
      $green_pea
    );
  }
}

.ButtonContainer {
  margin: 5px 10px 30px;
}

@media screen and (max-width: 1000px) {
  .CookiesManageModalItem {
    align-items: center;
  }

  .CookiesManageModalContainer {
    margin-left: 25px;
    margin-right: 25px;
    padding: 10px 20px 0;
  }

  .CookiesItemText {
    font-size: 11px;
    text-align: center;
    padding-left: 0;
  }

  .CheckBoxTitle {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .CheckBoxContainer {
    flex-direction: column;
  }
}

@media screen and (max-width: 800px) {
  .CookiesManageModalContainer {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .CookiesManageModalContainer {
    margin-left: 10px;
    margin-right: 10px;
  }
}
