
.SocialContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.SocialLink {
  margin-right: 14px;
}

.SocialLink:last-child {
  margin-right: 0px;
}