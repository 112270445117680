@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Input {
  &-Label {
    color: #999999;
    font-size: 18px;
    font-weight: 400;
    font-family: Roboto;
    line-height: 22px;
    margin: 0;
  }

  &-Container {
    display: flex;
    flex: 1;
    flex-direction: column;

    &_canError {
      min-height: 120px;
    }

    &_isFewInputsRow:first-child {
      margin-right: 48px;
    }
  }

  &-Error {
    margin: 0;
    padding: 0;
    @include font(
      $size: 16px,
      $family: Roboto,
      $weight: 400
    );
    color: $red;
  }

  &-Input {
    @include input;
    width: -webkit-fill-available;
    margin-top: 0px;
    margin-bottom: 0px;
    box-shadow: none;
    flex: 1;
    border-radius: 13px;
  }

  &-Input:focus {
    outline: none !important;
  }

  &-InputContainer {
    padding: 1px 1px;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 0px;
    border: 1px solid transparent;

    box-shadow: inset 0px 2px 0px #cccccc;
    border-radius: 12px;
    background-color: #eaeaea;

    &_canError {
      border: 1px solid $alabaster;
      margin-bottom: 8px;
    }

    &_readOnly {
      border: none;
    }
  }

  &-InputContainer:focus-within {
    border: 1px solid $black;
  }

  &-InputContainer_readOnly:focus-within {
    border: none;
  }

  &-InputButtonShow {
    border: none;
    background-color: $alabaster;
    cursor: pointer;
    display: flex;
    align-items: center;

    & p {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;

      color: $gray;
    }
  }
}

.ShowButtonDefaultIcon {
  width: 32px;
}

@media screen and (max-width: 380px) {
  .Input {
    &-Input_isButtonSecretShow {
      width: calc(230px - (380px - 100vw));
    }
  }
}
