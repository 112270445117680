@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.ModalContainer {
  background: rgba(28, 28, 28, 0.8);
  backdrop-filter: blur(4px);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 40px 0;
}

.ModalWrapper {
  margin: auto;
  position: relative;
}

.Modal {
  position: relative;
  background: $white;
  border-radius: 0px 0px 10px 10px;
  padding: 20px 10px 40px;
  box-shadow: 0px 10px 15px
    rgba(0, 0, 0, 0.25);
  transition: all 0.5s;
  width: 320px;
}

.TitleContainer {
  margin: 0 auto;
  background: $atlantis;
  min-height: 50px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  width: 320px;
}

.ButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-top: 3px;
}

.CopyLinkButtonContainer {
  margin-top: 20px;
}

.ModalClose {
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: -30px;
  right: -30px;
  cursor: pointer;
  z-index: 2;
}

.TitleOrangeButton {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
  color: $white;
  @include textBorder_2px_shadow($chocolate);

  &__Payments {
    font-size: 25px;
    line-height: 90%;
    &_Binance {
      font-size: 20px;
    }
  }
}

.LabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: $dark_gray;
}

.Input_InputContainer {
  margin: 0;
  background-color: #eeeeee;
  border-radius: 10px;
  box-shadow: inset 0px 2px 0px #cccccc;
  border: 1px solid #ffffff !important;
  flex: 0;
  width: 80px;
}

.Input_Container {
  background-color: transparent;
  height: 130px;
}

.Input_Input {
  box-shadow: unset;
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 38px;

  &::placeholder {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #676767;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.TitleText {
  text-align: center;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: $dove_gray;
  margin-bottom: 15px;
}

.CostContainer {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.TitleNewTotalText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #424242;
}

.TitleOldTotalTextContainer {
  margin-left: 8px;
  position: relative;
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TitleOldTotalLine {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 0;
}

.TitleOldTotalText {
  display: flex;
  flex: 1;
  white-space: pre;
  font-family: Fira Sans;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: $gray;
}

.ReferralContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.TitleEnterReferral {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #424242;
  margin-right: 45px;
}

.PackItemsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
}

.ItemsBlock {
  padding: 5px 0;
}

.PaymentsButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
  flex-direction: column;
}

.PaymentInfoContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.PaymentInfoTitle {
  margin-bottom: 8px;
  word-break: break-word;
  text-align: center;
  font-family: Fira Sans;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  align-items: center;
}

.PaymentInfoLink {
  font-family: Fira Sans;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  text-decoration: underline !important;
  word-break: break-word;
  color: $blue_sea;
}

.PaymentInfoAddress {
  font-family: Fira Sans;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  word-break: break-word;
  text-align: center;
  color: $blue_sea;
}

.PaymentInfoAttentionContainer {
  background: $light_gray_EA;
  border-radius: 20px;
  padding: 21px 30px 19px;
}

.PaymentInfoAttention {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $red;
}

.TitleTotalNowPaymentText {
  margin-bottom: 16px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: $red;
  text-align: center;
}

.BinanceQrCode {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 300px;
  height: 300px;
}

.NowPaymentQrCode {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  margin: 20px 0;
}

.InformationPopupCopied {
  top: 0;
  bottom: auto;
}

.LoadingTransferringPackContainer {
  position: relative;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CheckBoxContainer {
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
}

.CheckBoxTextContainer {
  display: flex;
  flex-wrap: wrap;
  width: 220px;
}

.CheckBoxImage {
  width: 30px;
  height: 30px;
}

.CheckBoxTitle {
  padding: 0 7.5px;
}

.RightsText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #424242;
  align-self: center;
}

a.RightsText {
  text-decoration: underline;
}

.ModalClose {
  top: -64px;
  right: -13px;

  & img {
    width: 36px;
    height: 36px;
  }
}

.Title {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 100%;
  text-align: center;
  color: $brown_dark;
}

.Button {
  width: 260px;

  &__Binance {
    padding: 14px 15px 16px 10px;
  }
}

.ButtonContainer:first-child {
  margin-bottom: 25px;
}

@media screen and (min-width: 640px) {
  .Modal {
    width: 620px;
  }

  .TitleContainer {
    width: 620px;
  }

  .ItemsBlock {
    padding: 10px;
  }

  .ItemsBlock:last-child {
    padding-bottom: 0;
  }

  .CostContainer {
    margin-bottom: 10px;
  }
  .PackItemsContainer {
    margin-bottom: 20px;
  }
}
