@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.QuestionFormContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Input_InputContainer {
  margin-top: 0px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;

  &__Question {
    min-height: 140px;
    height: auto;
    max-height: 400px;
  }
}

.Input_InputContainer:focus-within {
  border: none;
}

.Input_Container {
  width: 100%;
  margin-bottom: 10px;
}

.Input_Input {
  background-color: $hippie_green;
  border-radius: 15px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: $white;
  text-align: left;
  padding: 15.5px 20px;

  &::placeholder {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: $white;
  }

  &_TextArea {
    max-height: 400px;
    min-height: 140px;
  }
}

.Input_Input:focus-within {
  border: 2px solid #f2d302;
  padding: 13.5px 18px;
}

.SendButton {
  width: 140px;
}

.SendButtonContainer {
  width: 140px;
  margin-top: 30px;

  &__isError {
    margin-top: 4px;
  }
}

.TitleSendButton {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: $white;
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

.ErrorContainer {
  background: #fdfbd3;
  border: 2px solid #c1a55c;
  box-shadow: 0px 10px 10px
      rgba(0, 0, 0, 0.25),
    inset 0px -10px 4px #e9d7a7;
  border-radius: 15px;
  margin-bottom: 10px;
}

.ErrorText {
  padding: 16px 20px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: $chocolate;
  text-align: center;
}
