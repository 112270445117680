@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.TitleSeedPhrase {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: $cinnabar;
}

.SeedPraseContainer {
  margin-top: 27px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ImportantTitle {
  margin-top: 10px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  color: $cinnabar;
  &__description {
    color: $dark_gray_33;
  }
}

.ButtonsContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.CopyButtonContainer {
  position: relative;
}
.CopyButton {
  height: 66px;
  margin-bottom: 30px;
}

.CopiedContainer {
  bottom: auto;
  top: 0;
}

.TitleGreenButton {
  @include textBorder_4px_shadow($green_pea);

  &__android {
    @include textBorder_2px_shadow($green_pea);
  }
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow($chocolate);
  }
}

// Mobile version Modal, after 718px
@media screen and (max-width: 1000px) {
  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
  }
  .ButtonsContainer:first-child {
    margin-bottom: 30px;
  }
  .NextButton {
    height: 110px;
  }
}