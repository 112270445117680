@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  position: relative;
  z-index: 10;
}

.Title {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  /* disable select text */
  @include noselected();
  color: $white;
  fill: $white;

  &__SMALL {
    font-weight: 800;
    font-size: 18px;
  }

  &__MEDIUM {
    font-weight: 900;
    font-size: 30px;
  }

  &__LARGE {
    font-weight: 900;
    font-size: 50px;
  }
}

.TitleContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TitleStyle {
  display: flex;
  position: relative;
}

.IconButton {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.ProgressIndicator {
  position: absolute;
  z-index: inherit;
  top: 10px;
  width: 40px;
  height: 40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-image: url('../../assets/control/progress_button.png');
  background-repeat: no-repeat;
  background-size: contain;
  animation: progressAnimation 2s infinite
    linear;

  &__SMALL {
    width: 30px;
    height: 30px;
    top: 5px;
  }

  &__LARGE {
    width: 50px;
    height: 50px;
    top: 15px; // ? need check
  }
}

@keyframes progressAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* For Presale Html */
.CostContainer {
  display: flex;
  justify-content: center;
}

.TitleOldTotalTextContainer {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TitleOldTotalText {
  font-family: Fira Sans;
  font-weight: 900;
  font-size: 28px;
  line-height: 32px;
  color: $green_pea;
  text-shadow: initial;
}

.TitleOldTotalLine {
  position: absolute;
  width: 50%;
}

.PriceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PriceDiscountContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fb4517;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25),
    inset 0px 4px 2px #ff6725,
    inset 0px -4px 4px #d43a12;
  border-radius: 8px;
  padding: 0 6px;
}

.TetherUsdtIcon {
  width: 25px;
  &_Crossed {
    width: 22px;
  }
}

.PriceText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.PriceDiscountText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  @include textBorder_2px_shadow($chocolate);
}

.Button {
  height: 100px;
  border: 3px solid #ffffff;
  border-radius: 15px;
  padding: 9px 30px 11px;

  &__SMALL {
    height: 40px;
  }

  &__MEDIUM {
    height: 60px;
    padding: 14px 30px 16px;
  }

  &__LARGE {
    height: 80px;
    padding: 19px 55px 21px;
  }
}

.Button:after {
  border-radius: 18px;
  border: 7px solid #a3c2d0;
  opacity: 0.4;
  position: absolute;
  z-index: -1;
  content: '';
  top: -4px;
  left: -4px;
  bottom: -4px;
  right: -4px;
}

.Button__GREEN {
  background: linear-gradient(
    180deg,
    #55ee23 0%,
    #0da800 100%
  );
  box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.2),
    inset 0px 7px 6px 2px #adff5f;
  &:active:enabled {
    background: linear-gradient(
      180deg,
      #0d9801 2.6%,
      #0fc000 100%
    );
    box-shadow: 0px 5px 0px
        rgba(0, 0, 0, 0.2),
      inset 0px 7px 6px 2px #0b8800;
  }
}

.Button__ORANGE {
  background: linear-gradient(
    180deg,
    #fcb201 0%,
    #d16400 100%
  );
  box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.2),
    inset 0px 7px 6px 2px #f2b102;

  &:active:enabled {
    background: linear-gradient(
      180deg,
      #bd5a00 0%,
      #d16400 94.79%
    );
    box-shadow: 0px 5px 0px
        rgba(0, 0, 0, 0.2),
      inset 0px 7px 6px 2px #8e4400;
  }
}

.Button__BLUE {
  background: linear-gradient(
    360deg,
    #3548e4 0%,
    #02beed 100%
  );
  box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.2),
    inset 0px 7px 6px 2px #4edcff;

  &:active:enabled {
    background: linear-gradient(
      180deg,
      #2e5be6 0%,
      #5074e2 100%
    );
    box-shadow: 0px 5px 0px
        rgba(0, 0, 0, 0.2),
      inset 0px 7px 6px 2px
        rgba(0, 0, 0, 0.2);
  }
}

.Button__GRAY {
  background: #ABABAB;
  border: 3px solid #FFFFFF;
  box-shadow: inset 0px 7px 6px 2px #ABABAB;
}

@media (hover: hover) {
  .Button__GREEN:hover:enabled {
    background: linear-gradient(
      180deg,
      #55ee23 0%,
      #b5ff56 48.44%,
      #0da800 100%
    );
    box-shadow: 0px 5px 0px
        rgba(0, 0, 0, 0.2),
      inset 0px 7px 6px 2px #adff5f;
    cursor: pointer;
  }

  .Button__GREEN:active:enabled {
    background: linear-gradient(
      180deg,
      #0d9801 2.6%,
      #0fc000 100%
    );
    box-shadow: 0px 5px 0px
        rgba(0, 0, 0, 0.2),
      inset 0px 7px 6px 2px #0b8800;
  }

  .Button__ORANGE:hover:enabled {
    background: linear-gradient(
      180deg,
      #fcb201 0%,
      #faff00 47.4%,
      #d16400 100%
    );
    box-shadow: 0px 5px 0px
        rgba(0, 0, 0, 0.2),
      inset 0px 7px 6px 2px #f2b102;
    cursor: pointer;
  }

  .Button__ORANGE:active:enabled {
    background: linear-gradient(
      180deg,
      #bd5a00 0%,
      #d16400 94.79%
    );
    box-shadow: 0px 5px 0px
        rgba(0, 0, 0, 0.2),
      inset 0px 7px 6px 2px #8e4400;
  }

  .Button__BLUE:hover:enabled {
    background: linear-gradient(
      360deg,
      #02beed 0%,
      #1495ea 100%
    );
    box-shadow: 0px 5px 0px
        rgba(0, 0, 0, 0.2),
      inset 0px 7px 6px 2px #4edcff;
    cursor: pointer;
  }

  .Button__BLUE:active:enabled {
    background: linear-gradient(
      180deg,
      #2e5be6 0%,
      #5074e2 100%
    );
    box-shadow: 0px 5px 0px
        rgba(0, 0, 0, 0.2),
      inset 0px 7px 6px 2px
        rgba(0, 0, 0, 0.2);
  }
}
