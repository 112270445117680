$responsive-mobile: 375px;
$responsive-tablet-small: 760px;
$responsive-tablet: 1024px;
$responsive-desktop-small: 1420px;
$responsive-desktop: 1920px;

:root {
  --default-padding: 130px;
}

$defaultPadding: var(--default-padding);

@media (max-width: $responsive-desktop) {
  :root {
    --default-padding: 70px;
  }
}

@media (max-width: $responsive-desktop-small) {
  :root {
    --default-padding: 40px;
  }
}