@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.NicknameCostContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.NicknameCostText {
  display: flex;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  color: $dark_gray;
}

.HoneyIcon {
  margin-left: 16px;
  margin-right: 8px;
  width: 28px;
  height: 30px;
}

.ButtonsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
  margin-top: 23px;
}

.TitleGreenButton {
  @include textBorder_4px_shadow($green_pea);

  &__android {
    @include textBorder_2px_shadow(
      $green_pea
    );
  }
}

.TitleOrangeButton {
  @include textBorder_4px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

.Input_InputContainer {
  margin-top: 0px;
  border-radius: 15px;
  border: 1px solid transparent !important;
  box-shadow: inset 0px 2px 0px #cccccc;
  background-color: #eaeaea;
}

.Input_Container {
  background-color: transparent;
  height: 140px;
  margin-top: 8px;
}

.Input_Input {
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 70px;

  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $gray;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.LabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  color: $dark_gray;
}

.ChangeNicknameAttentionContainer {
  margin-top: 32px;
  background: $light_gray_EA;
  border-radius: 20px;
  // padding-top: 21px;
  // padding-bottom: 19px;
  padding: 21px 30px 19px;
}
.ChangeNicknameAttention {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $brown_dark;
}

@media screen and (max-width: 750px) {
  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
  }
  .ButtonContainer {
    height: 110px;
  }
}

// Mobile version Modal, after 718px
@media screen and (max-width: 1000px) {
  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
    margin-top: 3px;
  }
  .ButtonContainer:first-child {
    margin-bottom: 30px;
  }

  .Input_Container {
    background-color: transparent;
    height: 130px;
  }
  .Input_InputContainer {
    flex: 0;
  }

  .Input_Input {
    background-color: transparent;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    height: 48px;

    &::placeholder {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;

      color: $gray;
    }
  }

  .LabelInput,
  .NicknameCostText {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 360px) {
  .ButtonContainer {
    height: 90px;
    &__android {
      height: 110px;
    }
  }
}
