@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.ButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 3px;
  flex-direction: column;
  align-items: center;
}

.ButtonContainer:first-child {
  margin-bottom: 30px;
}

.ButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;

  &__Green {
    @include textBorder_2px_shadow(
      $green_pea
    );
  }

  &__Orange {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

.Button {
  width: 245px;
}

.Input_InputContainer {
  margin-top: 0px;
  border-radius: 12px;
  border: 1px solid transparent !important;
  box-shadow: inset 0px 2px 0px #cccccc;
  background-color: #eaeaea;
  flex: 0;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 48px;

  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $gray;
  }
}

.Input_Container {
  background-color: transparent;
  margin-top: 8px;
  height: 130px;
}

.Input_InvestContainer {
  background-color: transparent;
  margin-bottom: 15px;
}

.Input_Input {
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 48px;

  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $gray;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.LabelInput {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: left;
  color: $brown_dark;
  margin: 10px 0;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
}
