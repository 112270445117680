@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

// @media screen and (max-width: 375px) {
//   .ItemContainer {
//     min-width: unset;
//     width: 100%;
//     padding: 5px 10px;
//     // display: flex;
//   }
//   .PriceContainer {
//     display: flex;
//     width: 62px;
//   }
//   .IconContainer {
//     height: unset;
//     margin-right: 5px;
//   }
//   .ItemTextContainer {
//     min-width: 130px;
//     margin-right: 5px;
//   }
// }

// base
.ItemContainer {
  max-width: 280px;
  min-width: 280px;
  width: 280px;
  height: 120px;
  padding: 10px;
  background: #eeeeee;
  border-radius: 10px;
  display: flex;
  // justify-content: center;
  align-items: flex-start;
}

// .ItemBlock {
//   display: flex;
//   justify-content: center;
// }

.IconContainer {
  // height: 87px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.Icon {
  width: 100px;
  height: 100px;
}

.ItemTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  &__height {
    height: 100%;
    justify-content: center;
  }
}
.ItemTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 50px;
}

.ItemTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #424242;
}

.ItemDescriptionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 30px;
  margin-bottom: 10px;
  &__noHeight {
    // height: unset;
    display: none;
  }
}

.ItemDescription {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  color: #676767;
  text-align: left;
}

.PriceContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // flex: 1;
}

.TetherUsdtIcon {
  width: 20px;
  // position: relative;
  // top: 1px;
  margin-right: 5px;
  &__Display {
    &_none {
      display: none;
    }
  }
}

.ItemPrice {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  color: #424242;
  text-align: left;
  // @include textBorder_2px_shadow($green_pea);
  &__Free {
    color: #ff7a00;
    // @include textBorder_2px_shadow($chocolate);
    // &_mobile {
    //   font-size: 20px;
    //   line-height: 18px;
    // }
  }
}

/* @media screen and (min-width: 760px) {
  .ItemContainer {
    min-width: 335px;
    max-width: 335px;
    min-height: 88px;
    padding: 5px 16px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }

  .IconContainer {
    height: unset;
    margin-right: unset;
  }
  // .Icon {
  //   width: 65px;
  // }

  .ItemTextContainer {
    // min-width: 156px;
    // margin-right: 10px;
    min-width: 130px;
    margin-right: 5px;
    &__height {
      height: unset;
      margin-bottom: 0;
    }
  }

  .ItemTitleContainer {
    justify-content: flex-start;
    height: unset;
    min-width: 156px;
  }

  .ItemTitle {
    font-size: 19px;
    line-height: 23px;
    text-align: left;
    height: unset;
  }

  .ItemDescriptionContainer {
    justify-content: flex-start;
  }

  .ItemDescription {
    text-align: left;
  }

  .ItemPrice {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #9ed427;
    @include textBorder_2px_shadow($green_pea);
    &__Free {
      color: #ffb800;
      @include textBorder_2px_shadow($chocolate);
      &_mobile {
        font-size: 20px;
        line-height: 18px;
      }
    }
  }
  .ItemPrice__Free {
    font-size: 20px;
    line-height: 18px;
    text-align: left;
  }
} */
