@import '@src/styles/mixins.scss';
@import '@src/styles/colors.scss';

.InputContainer {
  position: relative;
  display: flex;
  flex: 0;
  border: 1px solid transparent;
  max-width: 100%;
  margin-right: 15px;
  margin-bottom: 10px;

  &_readOnly {
    border: none;
  }
}

.InputContainer:focus-within {
  border: 1px solid transparent;
}

.InputContainer_readOnly:focus-within {
  border: none;
}

.Input {
  padding: 5px 7px;
  border: 1px solid transparent;
  border-radius: 15px;
  max-width: calc(100%);
  background-color: $light_gray_EA;
  box-shadow: inset 0px 4px 10px
    $light_gray_BE;
  border: none;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  color: $brown_dark;

  &__error {
    background-color: $flamingo;
    box-shadow: inset 0px -4px 4px $watermelon;
  }
}

.Input:focus {
  outline: none !important;
}

.AbsoluteText {
  position: absolute;
  left: -1000px;
  top: -1000px;
  color: transparent;
  max-width: calc(100%);
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
}
