@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.TitleModalContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: -40px;
  height: auto;
  display: flex;
  margin: auto;
  justify-content: center;
  z-index: 1;

  &__isMedium {
    top: -30px;
  }
}
.TitleModal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $cinnabar;
  border-radius: 20px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), inset 0px -4px 4px #AA3B1E;
  padding: 20px 20px;
}
.Title {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 40px;
  /* or 100% */
  display: flex;
  // align-items: center;
  // text-align: center;

  color: $white;
  @include textBorder_2px_shadow($dark_tomato);
  &__isMedium {
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .TitleModal {
    padding: 10px 20px;
    &__isUnityGame {
      padding: 10px 15px;
    }
  }
  .TitleModalContainer {
    width: 240px;
    height: auto;
    &__isSmall {
      top: -25px;
    }
    &__isMedium {
      top: -40px;
    }
    &__isLarge {
      top: -60px;
    }
    &__isUnityGame {
      &__isSmall {
        width: auto;
        min-width: 240px;
        top: -15px;
      }
      &__isMedium {
        top: -25px;
        width: auto;
        min-width: 240px;
      }
    }
  }
  .Title {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;

    &__isUnityGame {
      &__isSmall {
        font-size: 16px;
        line-height: 18px;
      }
      &__isMedium {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
