@import '@src/styles/mixins.scss';
@import '@src/styles/colors.scss';

.AvatarIconContainer {
  position: relative;
  display: flex;
}

.AvatarIconShadow {
  position: absolute;
  top: 4px;
  width: 100%;
  height: 100%;
  border-radius: calc(100% / 2);
  background-color: rgba(0, 0, 0, 0.25);
}

.AvatarIcon {
  position: relative;
  width: 100%;
}
