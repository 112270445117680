@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.ModalContainer {
  background: rgba(28, 28, 28, 0.8);
  backdrop-filter: blur(4px);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 40px 10px 10px;
  overflow-x: hidden;
}

.Modal {
  margin: auto;
  position: relative;
  background: $white;
  border-radius: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
  transition: all 0.5s;
  width: calc(100vw - 40px);
  max-width: 620px;
}

.ModalClose {
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: -30px;
  right: -30px;
  cursor: pointer;
  z-index: 2;
}

.TitleContainer {
  margin: 0 auto;
  background: $atlantis;
  min-height: 50px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  flex: 1;
}

.Title {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 100%;
  text-align: center;
  color: $brown_dark;
}

// TODO: New 

.ModalClose {
  top: -14px;
  right: -13px;
  & img {
    width: 36px;
    height: 36px;
  }
}

.TitlePopup {
  top: -30px;
  height: 60px;
  width: 240px;
}

.Title {
  background: $atlantis;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 100%;
  color: $brown_dark;
  text-align: center;
  padding: 14px 30px;
}

.InnerModalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 20px 40px 20px;
}

@media screen and (min-width: 700px) {
  .PopUp {
    margin: auto;
    position: relative;
    width: 620px;
    transition: all 0.5s;
  }
  .PopUpTextContainer {
    padding: 50px 80px 40px;
  }
}

@media screen and (max-width: 500px) {
  .ButtonsContainer {
    flex-direction: column;
  }
  .ButtonContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
  }
}