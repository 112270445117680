@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 700px;
}

.BackgroundImagesContainer {
  display: flex;
}

.BackgroundMobileImage {
  width: 360px;
}

.BackgroundTabletImage {
  width: 1020px;
  display: none;
}

.BackgroundDesktopImage {
  width: 1920px;
  display: none;
}

.TitleContainer {
  padding: 0 20px;
  margin-top: 160px;
  margin-bottom: 130px;
}

.CaptionTitleContainer {
  max-width: 610px;
}

.InfoContainer {
  width: 100%;
  position: absolute;
  top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.CaptionSubtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.PartnershipSecondSubtitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: $white;
  margin-top: 20px;
  max-width: 590px;
}

.TitleButton {
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
}

.BecomeButton {
  width: 310px;
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);
  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

@media screen and (min-width: 359.99px) {
  .BackgroundMobileImage {
    display: none;
  }

  .BackgroundTabletImage {
    display: initial;
  }

  .BackgroundImagesContainer {
    width: 100%; // makes the image view from the beginning
  }
}

@media screen and (min-width: 1019.99px) {
  .BackgroundTabletImage {
    width: 100%;
  }

  .TitleContainer {
    margin-top: 177px;
  }

  .CaptionTitle {
    font-size: 60px;
  }
}

@media screen and (min-width: 1300px) {
  .CaptionTitleContainer {
    max-width: 942px;
  }

  .CaptionTitle {
    font-size: 80px;
    margin-bottom: 30px;
  }

  .CaptionSubtitle {
    font-size: 20px;
    line-height: 120%;
  }

  .TitleContainer {
    margin-top: 130px;
  }
}

@media screen and (min-width: 1418px) {
  .BackgroundTabletImage {
    display: none;
  }

  .BackgroundDesktopImage {
    display: initial;
  }
}

@media screen and (min-width: 1919.99px) {
  .BackgroundDesktopImage {
    width: 100%;
  }
}
