@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.EnterSeedPhraseTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: $brown_dark;
  margin: 20px 20px 0;
}

.SeedPraseContainerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SeedPraseContainer {
  margin-top: 27px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-shadow: inset 0px 2px 0px #cccccc;
  border-radius: 12px;
  background-color: #eaeaea;
  padding: 11px 5px 0;
  min-width: 330px;
  max-width: 330px;
}

.WordInput_InputContainer {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  border-radius: unset;
  border: none;
}

.WordInput_InputContainer:focus-within {
  border: none;
}

.WordInput_Input {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  border-radius: unset;
  box-shadow: unset;
  border-right: 1px solid #dadada;
  padding: 0 5px;
  margin-bottom: 10px;

  &__noRightBorder {
    border-right: none;
  }

  &::placeholder {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
}

.EnterAllWordsTitle {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: $red;
}

.PaddingInnerContainer {
  padding: 0 55px;
}

.InputContainerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InputContainer {
  height: 80px;
  border-bottom: 1px solid #e9e9e9;
  padding-top: 16px;
}

.InputLabel {
  font-size: 12px;
  line-height: 16px;
  color: $orange;
}

.Input {
  padding: 2px 0 18px;
  width: 100%;
  border: none;
  outline: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: $dark_black;
}

.ImportantTitle {
  margin-top: 30px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: $cinnabar;

  &__description {
    color: $brown_dark;
  }

  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

.PasteButtonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginInsertPhraseContainer {
  margin-top: 32px;
  background: $light_gray_EA;
  border-radius: 20px;
  padding: 21px 30px 19px;
}

.LoginInsertPhrase {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $brown_dark;
}

.ButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;

  &__Small {
    font-size: 20px;
  }

  &__Green {
    @include textBorder_2px_shadow(
      $green_pea
    );
  }

  &__Orange {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

.Button {
  width: 180px;
}

.Input_InputContainer {
  margin-top: 0px;
  border-radius: 12px;
  border: 1px solid transparent !important;
  box-shadow: inset 0px 2px 0px #cccccc;
  background-color: #eaeaea;
}

.Input_Container {
  background-color: transparent;
  height: 140px;
  margin-top: 8px;
  padding: 0;
  max-width: 330px;
  min-width: 330px;
}

.Input_Input {
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  height: 34px;

  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #676767;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.LabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  color: $brown_dark;
  margin: 10px 0 10px;
}

.BackButton {
  margin-right: 20px;
}

@media screen and (max-width: 1000px) {
  .ButtonContainer:first-child {
    margin-bottom: 30px;
  }

  .Input_Container {
    background-color: transparent;
    height: 130px;
  }

  .Input_InputContainer {
    flex: 0;
  }

  .Input_Input {
    background-color: transparent;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    height: 34px;

    &::placeholder {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: $gray;
    }
  }

  .LabelInput {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 100%;
    text-align: left;
    color: $brown_dark;
  }

  .ImportantTitle {
    display: block;
  }
}

@media screen and (max-width: 500px) {
  .PaddingInnerContainer {
    padding: 0;
  }

  .Input_Container {
    max-width: 250px;
    min-width: 250px;
  }

  .BackButton {
    margin-right: 0;
  }

  .LoginButton {
    margin-bottom: 20px;
  }

  .ButtonContainer {
    flex-direction: column-reverse;
  }

  .WordInput_Input {
    font-size: 14px;
    padding: 0;
  }

  .SeedPraseContainer {
    min-width: 250px;
    max-width: 250px;
  }
}
