@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.GreenWrapperContainer {
  position: relative;
  background: $green_mobile;
  z-index: 2;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;
  background: $green_mobile;
  padding: 40px 10px;
}

.TeamCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.TeamCard {
  position: relative;
  width: 150px;
  min-height: 153px;
  border-radius: 10px;
  margin: 10px;
  background: $dark_green_mobile;

  &__margin {
    &_top {
      margin-top: 0;
    }
  }
}

.TeamItemImage {
  width: 150px;
  display: block;

  &__Advisor {
    width: 100px;
    top: -51px;
    left: 121px;
  }
}

.TeamItemDescription {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding: 0 20px 20px;
}

.TeamItemsContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  max-width: 680px;
}

.TeamNameContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 5px 0;

  &__Advisor {
    padding: 60px 5px 0;
  }
}

.TeamName {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: $white;
  margin-bottom: 10px;
}

.TeamPosition {
  padding: 0 20px 16px;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: $light_green_mobile;
  min-height: 45px;

  &__Representatives {
    padding: 0;
    min-height: 20px;
  }
  &__LongName {
    padding: 0 20px 0;
    min-height: 25px;
  }
}

.SocialContainer {
  margin-bottom: 18px;
}

.TitleContainer {
  margin-bottom: 10px;
}

.ShowMoreButton {
  padding: 10px 19px;
}

.ButtonContainer {
  margin-top: 20px;
  margin-bottom: 40px;
}

.TitleButton {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: $white;
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

.GradientBottom {
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 24px;
  z-index: 1;
}

.RepresentativesContainerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
}

.RepresentativesContainer {
  width: 100%;
  background: $dark_green_mobile;
  border-radius: 20px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 40px 20px 0;
  margin-bottom: 10px;
}

.RepresentativesCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.RepresentativesItemDescription {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: $light_green_mobile;
}

.RepresentativeImage {
  width: 90px;
  margin-right: 20px;
}

.RepresentativesInfoBlock {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
}

.RepresentativesInfo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.RepresentativesPosition {
  align-items: flex-start;
}

.RepresentativesName {
  margin-bottom: 2px;
}

@media screen and (min-width: 500px) {
  .RepresentativesCard {
    flex-direction: row;
    align-items: flex-start;
  }

  .RepresentativesInfoBlock {
    margin-bottom: 0;
    margin-right: 30px;
    align-items: center;
  }

  .RepresentativesTitleContainer {
    margin-bottom: 30px;
  }

  .RepresentativesName {
    width: 120px;
    text-align: left;
  }
}

@media screen and (min-width: 700px) {
  .RepresentativesInfoBlock {
    margin-right: 70px;
  }

  .RepresentativesContainer {
    padding: 40px 60px 60px;
    margin-bottom: 40px;
  }

  .RepresentativesTitleContainer {
    margin-bottom: 50px;
  }

  .TitleContainer {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1000px) {
  .TeamCardContainer {
    margin: 5px;
  }

  .TeamItemsContainer {
    max-width: 720px;
  }
}

@media screen and (min-width: 1300px) {
  .TeamItemsContainer {
    &__Max_width {
      &_910px {
        max-width: 910px;
      }
    }
  }

  .RepresentativesContainer {
    width: 1180px;
    padding: 40px 60px 60px;
    margin-bottom: 0;
  }

  .RepresentativesCard {
    margin-bottom: 60px;
  }

  .RepresentativesItemDescription {
    font-size: 18px;
  }
}
