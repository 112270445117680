@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;
  background-color: $green_mobile;
  padding: 40px 0;
}

.ArticleWrapperLink {
  text-decoration: none;
}

.DirectQuotesImageWrapper {
  display: flex;
  align-items: flex-start;
  margin-right: 10px;
}

.BackQuotesImageWrapper {
  display: flex;
  align-items: flex-end;
  margin-left: 10px;
}

.ArticleQuote {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: left;
  color: $white;
}

.ArticleCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 960px;
}

.ArticleCard {
  display: flex;
  flex-direction: column;
  width: 280px;
  min-height: 234px;
  border-radius: 10px;
  margin: 15px;
  background: $dark_green_mobile;
}

.ArticleItemImage {
  width: 100%;
}

.ArticleCardInfoContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.ArticleCardInfoWrapper {
  padding: 20px 20px 20px 30px;
  display: flex;
}

.ShowMoreButton {
  padding: 10px 19px;
}

.TitleButton {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: $white;
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

@media screen and (max-width: 650px) {
  .TitleSectionInnerContainer {
    margin-bottom: 10px;
  }

  .ArticleCard {
    width: 320px;
    margin: 20px;

    &__lastCard {
      min-height: unset;
      margin-bottom: 0;
    }
  }

  .Container {
    padding: 40px 5px 20px;
  }

  .ShowButtonContainer {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .HideButtonContainer {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .GradientBottom {
    position: absolute;
    bottom: 130px;
    width: 100%;
    height: 130px;
    display: initial;
  }
}

@media screen and (min-width: 1300px) {
  .TitleSectionOuterContainer {
    margin-bottom: 15px;
  }

  .Container {
    padding: 90px 0;
  }
}
