@import '@src/styles/mixins.scss';
@import '@src/styles/colors.scss';

.RadioButton {
  &_Container {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    &_disabled {
      cursor: default;
    }
    &:focus-within {
      outline: none !important;
    }
  }

  &_OuterCircle {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    transition: all 0.1s linear;
    &_selected {
      border: 1px solid $green_pea;
    }
  }

  &_InnerCircle {
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: $green_pea;
    transition: all 0.1s linear;
    &_selectedCircle {
      width: 8px;
      height: 8px;
    }
  }

  &_Title {
    padding-right: 8px;
    @include font($size: 14px, $family: Roboto, $weight: 400, $line-height: 22px);
    // color: #262626;
    color: #246916;
    &_selected {
      color: #246916AA;
      @include font($size: 14px, $family: Roboto, $weight: 500, $line-height: 22px);
    }
    &_disabled {
      color: #999999;
    }
  }
}
