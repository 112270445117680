@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  position: relative;
  top: -20px;
  z-index: 3;
  padding: 50px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.TitleContainer {
  margin-bottom: 26px;
  max-width: 740px;
}

.SubscribeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 60px;
  width: 100%;
  max-width: 580px;
}

.SendButton {
  width: 140px;
}

.SendButtonContainer {
  margin-bottom: 60px;
  width: 140px;
}

.TitleButton {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: $white;
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

.SecondTitleContainer {
  margin-bottom: 0;
  max-width: 740px;
}

@media screen and (min-width: 700px) {
  .Container {
    padding: 75px 20px 20px;
  }
}
