@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.EnterSeedPhraseTitle {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: $dark_gray_33;
}

.SeedPraseContainer {
  padding-left: 15px;
  margin-top: 27px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.EnterAllWordsTitle {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: $red;
}

.InputContainer {
  height: 80px;
  border-bottom: 1px solid #e9e9e9;
  padding-top: 16px;
}

.InputLabel {
  font-size: 12px;
  line-height: 16px;
  color: $orange;
}

.Input {
  padding: 2px 0 18px;
  width: 100%;
  border: none;
  outline: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: $dark_black;
}

.ImportantTitle {
  margin-top: 10px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  color: $cinnabar;
  &__description {
    color: $dark_gray_33;
  }
}

.ButtonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.LoginInsertPhraseContainer {
  margin-top: 32px;
  background: $light_gray_EA;
  border-radius: 20px;
  // padding-top: 21px;
  // padding-bottom: 19px;
  padding: 21px 30px 19px;
}
.LoginInsertPhrase {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $brown_dark;
}

.TitleGreenButton {
  @include textBorder_4px_shadow($green_pea);

  &__android {
    @include textBorder_2px_shadow($green_pea);
  }
}

.Input_InputContainer {
  margin-top: 0px;
  background-color: $light_gray_EA;
  border-radius: 15px;
  box-shadow: inset 0px 4px 10px #bebebe;
  border: 1px solid transparent !important;
}

.Input_Container {
  background-color: transparent;
  height: 140px;
  margin-top: 8px;
}

.Input_Input {
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 70px;

  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $gray;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.LabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  color: $dark_gray;
}

// Mobile version Modal, after 718px
@media screen and (max-width: 1000px) {
  .EnterSeedPhraseTitle {
    font-size: 20px;
    line-height: 24px;
  }

  .ButtonContainer {
    flex-direction: column;
    align-items: center;
  }
  .ButtonContainer:first-child {
    margin-bottom: 30px;
  }
  .LoginContainer {
    height: 110px;
  }

  .Input_Container {
    background-color: transparent;
    height: 130px;
  }
  .Input_InputContainer {
    flex: 0;
  }

  .Input_Input {
    background-color: transparent;
  
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  
    height: 48px;
    &::placeholder {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
  
      color: $gray;
    }
  }

  .LabelInput {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
  .ImportantTitle {
    display: block;
  }
}