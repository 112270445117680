@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.GreenWrapperContainer {
  position: relative;
  background: $green_mobile;
  z-index: 2;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $packs_title_dark_green_background;
  position: relative;
  z-index: 2;
  top: -20px;
  border-radius: 20px;
  padding: 40px 0;
}

.Title {
  margin-bottom: 30px;
}

.PhasesBlock {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.PhaseContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
  padding: 0 20px;
  max-width: 350px;
}

.PhaseContainer:last-child {
  margin-bottom: 50px;
}

.PhaseTitleBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.PhaseTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 100%;
  color: $yellow_green;
  margin-right: 15px;
}

.PhaseTitleGreenLine {
  width: 100%;
  height: 3px;
}

.PhaseStep {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.PhaseStepInfo {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.PhaseStepIcon {
  width: 50px;
}

.PhaseStepDescriptionWrapper {
  display: flex;
}

.PhaseStepDescriptionTextWrapper {
  flex-basis: content;
}

.PhaseStepDate {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: $yellow_green;

  &_color {
    &__red {
      color: red;
    }
  }
  margin-bottom: 0;
  text-align: left;
}

.PhaseStepDescription {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: $white;
  text-align: left;

  &__crossedOut {
    text-decoration: line-through;
  }

  &__isActive {
    color: $selective_yellow;
  }
}

.DoneIconWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.DoneIcon {
  margin-left: 8px;
}

.TitleButton {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
  text-align: center;
  color: $white;
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

.PhaseStepDateWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 499.99px) {
  .Container {
    padding: 40px 10px;
  }
}

@media screen and (min-width: 1000px) {
  .Title {
    margin-bottom: 50px;
  }

  .PhaseContainer {
    max-width: 320px;
  }
}

@media screen and (min-width: 1300px) {
  .PhaseContainer {
    max-width: 360px;
    margin: 0 60px 60px;
  }

  .PhaseContainer:first-child {
    margin-left: unset;
    margin-right: unset;
  }

  .PhaseContainer:last-child {
    margin-left: unset;
    margin-right: unset;
  }
}
