@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

// .TokenomicsSectionContainer {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// }

.Container {
  width: 100%;
  position: relative;
  top: -20px;
  z-index: 2;
  background: $packs_title_dark_green_background;
  border-radius: 20px;
  padding: 40px 20px 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.InfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.TitleContainer {
  margin-bottom: 30px;
}

.CoinsContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}

.CoinWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
  max-width: 400px;
  flex: 1;
}

.CoinWrapper:last-child {
  margin-bottom: 0;
}

.CoinSubTitleTextWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.CoinIcon {
  width: 70px;
  margin-bottom: 6px;
}

.CoinTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  color: $white;
  margin-bottom: 10px;
}

.CoinSubTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: $white;
  text-align: left;
  margin-bottom: 10px;
}

.CoinText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: $white;
  text-align: left;
  margin-bottom: 40px;
  height: 100px;
}

.TotalSupplyTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $white;
}

.TotalSupplyCoins {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: $white;
  margin-bottom: 20px;
}

.CoinBarChart {
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
}

.CoinsDistributionContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.HoneyCoinDistributionWrapper {
  margin-right: 20px;
}

.ConeDistributionWrapper:first-child {
  margin-right: 20px;
}

.PercentagesTextWrapper {
  display: flex;
  margin-bottom: 10px;
}

.PercentagesText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  color: $white;

  &__Liquidity {
    color: $gold;
  }
  &__Igo {
    color: $honeysuckle;
  }
  &__WebSale {
    color: $atlantis;
  }
  &__HoneyWoodTeam {
    color: $aqua;
  }
  &__Private {
    color: $picton_blue;
  }
  &__Marketing {
    color: $bondi_blue;
  }
  &__Advisory {
    color: $blaze_orange;
  }
  &__Reward {
    color: $thunderbird;
  }
}

@media screen and (min-width: 380px) {
  .HoneyCoinDistributionWrapper {
    margin-right: 40px;
  }
}

@media screen and (min-width: 400px) {
  .ConeDistributionWrapper {
    margin-right: 20px;
  }
}

@media screen and (min-width: 420px) {
  .HoneyCoinDistributionWrapper {
    margin-right: 60px;
  }

  .ConeDistributionWrapper {
    margin-right: 40px;
  }
}

@media screen and (min-width: 750px) {
  .CoinsContainer {
    flex-direction: row;
  }

  .CoinWrapper {
    margin-bottom: 0;
  }

  .CoinWrapper:first-child {
    margin-right: 40px;
  }

  .HoneyCoinDistributionWrapper {
    margin-right: 20px;
  }

  .ConeDistributionWrapper {
    margin-right: 20px;
  }

  .Container {
    padding: 30px 40px 90px;
  }

  .CoinSubTitle {
    height: 34px;
  }
}

@media screen and (min-width: 900px) {
  .CoinWrapper:first-child {
    margin-right: 80px;
  }

  .Container {
    padding: 30px 70px 90px;
  }

  .ConeDistributionWrapper {
    margin-right: 0;
  }
}

@media screen and (min-width: 940px) {
  .ConeDistributionWrapper {
    margin-right: 20px;
  }
}

@media screen and (min-width: 990px) {
  .CoinSubTitle {
    height: unset;
  }
}

@media screen and (min-width: 1020px) {
  .CoinsContainer {
    width: 870px;
  }
}

@media screen and (min-width: 1300px) {
  .CoinsContainer {
    width: 1180px;
  }

  .CoinWrapper:first-child {
    margin-right: 0;
  }
}
