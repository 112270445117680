@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.InputContainer {
  height: 80px;
  border-bottom: 1px solid #e9e9e9;
  padding-top: 16px;
}

.InputLabel {
  font-size: 12px;
  line-height: 16px;
  color: $orange;
}

.Input {
  padding: 2px 0 18px;
  width: 100%;
  border: none;
  outline: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: $dark_black;
}

.SendButton {
  display: flex;
  justify-content: center;
}

.ModalText {
  padding-top: 30px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $gray;
}

.ModalInvestText {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  color: $dark_gray;
  padding: 0 0 12px;
}
.CheckBoxContainer {
  display: flex;
}

.CheckBoxImage {
  width: 50px;
  height: 50px;
}

.CheckBox {
  margin-bottom: 16px;
}

.LabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  color: $dark_gray;
}

.TitleGreenButton {
  @include textBorder_4px_shadow($green_pea);

  &__android {
    @include textBorder_2px_shadow($green_pea);
  }
}

.Input_InputContainer {
  margin-top: 0px;
  flex: 1;
  // height: 66px;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid transparent !important;
}

.Input_Container {
  background-color: transparent;
  height: 140px;
}
.Input_InvestContainer {
  background-color: transparent;
  // height: 140px;
  margin-bottom: 15px;
}

.Input_Input {
  background-color: $light_gray_EA;
  border-radius: 15px;
  box-shadow: inset 0px 4px 10px #bebebe;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  height: 70px;
  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: $gray;
  }
}

.ModalInvestSlider {
  -webkit-appearance: none;
  margin-top: 30px;
  margin-bottom: 45px;
  width: 100%;
  height: 10px;
  background: #eaeaea;
  border-radius: 5px;
  background-image: linear-gradient(#ff4500, #ff4500);
  background-size: 1% 100%;
  background-repeat: no-repeat;
}

/* Input Thumb */
.ModalInvestSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 54px;
  height: 75px;
  border-radius: 50%;
  cursor: ew-resize;
  background: no-repeat url('../../../assets/ContactUsModal/pagination_button_active.png');
  background-size: 67px 64px;
  background-position: center 9px;
  box-shadow: none;
  border: none;
}

/* Input Track */
.ModalInvestSlider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.ModalInvestSlider:focus {
  outline: none;
}

// Mobile version Modal, after 718px
@media screen and (max-width: 1000px) {
  .Input_Container {
    background-color: transparent;
    height: 130px;
  }
  .Input_InputContainer {
    flex: 0;
  }

  .Input_Input {
    background-color: $light_gray_EA;
    border-radius: 15px;
    box-shadow: inset 0px 4px 10px #bebebe;
  
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  
    height: 48px;
    &::placeholder {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
  
      color: $gray;
    }
  }

  .LabelInput {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }

  .ModalInvestText {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
}