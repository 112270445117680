@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.InfoTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: $brown_dark;
  margin: 20px;
}

.DescriptionModal {
  margin-top: 14px;
  font-family: 'Fira Sans';
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $brown_dark;
}

.ButtonsContainer {
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.EnterAllWordsTitle {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $red;
}

.ButtonContainer {
  width: 210px;
  margin: 0 10px;
}

.Button {
  width: 210px;
}

.ButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  &__Green {
    @include textBorder_2px_shadow($green_pea);
  }
  &__Orange {
    @include textBorder_2px_shadow($chocolate);
  }
  &__Gray {
    @include textBorder_2px_shadow($black);
  }
}

@media screen and (max-width: 550px) {
  .ButtonContainer {
    width: 210px;
    margin: 10px 0;
  }

  .ButtonsContainer {
    flex-direction: column;
  }
}