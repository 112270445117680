@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.SpecialPresaleSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Container {
  width: 100%;
  position: relative;
  top: -20px;
  z-index: 4;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: $dark_green_mobile;
  border-radius: 20px;
  padding: 40px 20px;
}

.LeftSnowIcon {
  position: absolute;
  left: -1px;
  top: -25px;
}

.RightSnowIcon {
  position: absolute;
  right: -1px;
  top: -25px;
}

.EventContainer {
  width: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  padding: 40px 20px;
}

.LightBackgroundEventPack {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; // lock of copy image
  width: 550px;
}

.LoadingContainer {
  position: relative;
  width: 150px;
}

.ContainerTitle {
  @include textBorder_3px_shadow($green_pea);
  -webkit-text-stroke: unset;
}
.PacksBlockContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.PacksTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 100%;
  text-align: center;
  color: $white;
  margin-bottom: 15px;
}
.PacksTitleHalloween {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 100%;
  text-align: center;
  color: $gold;
  margin-bottom: 15px;
  @include textBorder_2px_shadow(#2C2C2C);
}
.PacksTitleChristmas {
  @include textBorder_2px_shadow(#FF9733);
}
.PacksBlockWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.ButtonContainer {
  margin-top: 10px;
}
.TitleButton {
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow($chocolate);
  }
}

.ItemContainer {
  width: 320px;
  border-radius: 10px;
  margin: 20px 15px;
  position: relative;
  &__noActive {
    opacity: 0.5;
  }
  &__enabled {
    &:active {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}

@media (hover: hover) {
  .ItemContainer__enabled:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .ItemContainer__enabled:active {
    opacity: 0.7;
    cursor: pointer;
  }
}
.NewPackIcon {
  position: absolute;
  left: -20px;
  top: -20px;
  width: 60px;
  height: 60px;
  z-index: 10;
}

.ItemImage {
  border-radius: 10px;
  width: 320px;
  display: block;
}
.ItemInfoContainer {
  display: flex;
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.ItemTitleContainer {
  background: $packs_title_dark_green_background; // winter - #14445F; summer - $packs_title_dark_green_background;
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 10px;
  padding: 0 30px 0 20px;
}
.ItemTitleContainerHalloween {
  background: #7151AA;
}
.ItemTitleContainerChristmas {
  background: #2996ED;
  width: 70%;
}
.ItemTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 100%;
  text-align: left;
  color: $white;
  &__isDiscount {
    @include textBorder_2px_shadow(#731386); // winter - comment this field;
  }
}
.ItemTitleHalloween {
  @include textBorder_2px_shadow(#731386);
}
.ItemTitleChristmas {
  @include textBorder_2px_shadow(#256FAA);
}
.ItemPriceContainer {
  background: $packs_price_dark_red_background;
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 0;
  &__isDiscount {
    flex-direction: column;
    background: #FFA40A; // winter - #EF3B3B; summer - #FFA40A;
  }
  &__isChristmas {
    background: #EF3B3B;
  }
}

.ItemPrice {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  color: $white;
  &__isDiscount {
    font-size: 21px;
    line-height: 120%;
    @include textBorder_2px_shadow(#BB4100);
  }
  &__isChristmas {
    @include textBorder_2px_shadow(#A51908);
  }
}

.ItemDiscountContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TitlePriceOld {
  font-family: Fira Sans;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: #782201;
  text-shadow: initial;
  &__Christmas {
    color: #A51908;
  }
}

.TitlePriceOldLine {
  position: absolute;
  width: 25%;
}

@media screen and (min-width: 499.99px) {
  .Container {
    padding: 40px 15px;
  }
}

@media screen and (min-width: 1300px) {
  .Container {
    width: 1180px;
  }
}

@media screen and (max-width: 550px) {
  .LightBackgroundEventPack {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none; // lock of copy image
    width: calc(100vw + 50px);
  }
}
