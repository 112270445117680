@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.PartnersSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Container {
  width: 100%;
  position: relative;
  top: -20px;
  z-index: 6;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: $packs_title_dark_green_background;
  border-radius: 20px;
  padding: 40px 20px;
}

.LeftSnowIcon {
  position: absolute;
  left: -1px;
  top: -25px;
}

.RightSnowIcon {
  position: absolute;
  right: -1px;
  top: -25px;
}

.PartnersContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 820px;
}

.PartnerLogoImage {
  padding: 0 20px;
  width: 160px;
  &__additionalOption {
    max-height: 40px;
    width: auto;
  }
}

.TitleButton {
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
}

.BecomeButtonContainer {
  margin-top: 10px;
}
.BecomeButton {
  // If the width is < 350px - it is necessary to reduce the font
  width: 310px;
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

@media screen and (min-width: 1300px) {
  .Container {
    width: 1180px;
    padding: 50px 20px;
  }

  .TitleSectionInnerContainer{
    margin-bottom: 10px;
  }
}
