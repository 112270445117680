@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.GameInfoSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.GameInfoSection {
  max-width: 1220px;
  position: relative;
  z-index: 5;
  padding: 60px 20px;
}

.TitleSectionWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  max-width: 515px;
}

.Title {
  text-align: left;
  font-size: 50px;
}

.GameInfoItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
}

.GameInfoItem {
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.GameInfoItemTextContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 180px;
}

.GameInfoTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: left;
  color: $white;
  margin-bottom: 10px;
}

.GameInfoText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: left;
  color: $light_green_mobile;
}

.OxygenImage {
  width: 140px;
}

.Match3Image {
  width: 140px;
}

.ApiaryImage {
  width: 140px;
}

.FlexRowReverse {
  flex-direction: row-reverse;
}

.ButtonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;

  &__Display {
    &_none {
      display: none;
    }
  }
}

.PlayButtonContainer {
  margin-right: 30px;
}

.PlayButton {
  width: 140px;
}

.BuyButton {
  width: 140px;
}

.TitleButton {
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
}

.TitleGreenButton {
  @include textBorder_2px_shadow(
    $light_green_button_text
  );

  &__android {
    @include textBorder_2px_shadow(
      $light_green_button_text
    );
  }
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
}

@media screen and (min-width: 500px) {
  .GameInfoItem {
    padding: 15px;
    flex-direction: column;
  }

  .GameInfoTitle {
    text-align: center;
  }

  .GameInfoText {
    text-align: center;
  }

  .GameInfoItemTextContainer {
    align-items: center;
  }
}

@media screen and (min-width: 749.99px) {
  .GameInfoItem {
    padding: 15px 25px;
  }
}

@media screen and (min-width: 799.99px) {
  .GameInfoItem {
    padding: 15px 35px;
  }
}

@media screen and (min-width: 899.99px) {
  .GameInfoItem {
    padding: 15px 50px;
  }
}

@media screen and (min-width: 1300px) {
  .GameInfoSection {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .GameInfoItem {
    padding: 15px 30px;
  }

  .GameInfoItem:first-child {
    padding-left: 0;
  }

  .GameInfoItem:last-child {
    padding-right: 0;
  }

  .ButtonsContainer {
    margin-top: 20px;
  }
}
