@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  position: relative;
  top: -20px;
  z-index: 2;
  background: $packs_title_dark_green_background;
  border-radius: 20px;
  padding: 40px 0 30px;
}

.InfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.HowToPlayButtonContainer {
  position: relative;
}

.HowToPlayButtonImage{
  padding-left: 10px;;
}

.TitleContainer {
  padding: 0 20px;
  margin-bottom: 30px;
}

.ButtonContainer {
  position: relative;
  top: -44px;
  width: 240px
}
.HowToPlayButton {
  width: 240px;
}

.TitleButton {
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow($chocolate);
  }
}

.GameConceptSlideContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.SlideTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: $white;
  text-align: center;
  margin-bottom: 10px;
}

.PlayVideoIconWrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:active {
    opacity: 0.7;
    cursor: pointer;
  }
}
.SwiperWrapper {
  margin-bottom: 60px;
}

@media (hover: hover) {
  .PlayVideoIconWrapper:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .PlayVideoIconWrapper:active {
    opacity: 0.7;
    cursor: pointer;
  }
}

.PlayVideoIcon {
  position: absolute;
  margin: auto;
}

.SlideImage {
  width: 310px;
  height: 180px;
  border-radius: 10px;
  margin: 0 10px;
}

.SwiperSlide {
  width: auto;
}

.ParametersIndicatorContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 0 20px;
}

.IndicatorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 30px;
  &__Clickable:active {
    cursor: pointer;
    text-decoration: underline;
  }
}
@media (hover: hover) {
  .IndicatorContainer__Clickable:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.IndicatorIcon {
  margin-bottom: 8px;
}

.IndicatorTextInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.IndicatorTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: $white;
  text-align: center;
}
.IndicatorParameters {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: $white;
  text-align: center;
}

@media screen and (min-width: 749.99px) {
  .InfoContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0 20px;
  }
  .Container {
    padding: 40px 0;
  }
  .ParametersIndicatorContainer {
    padding: 0 0 0 50px;
  }
  .IndicatorContainer {
    flex-direction: row;
    margin: 15px 20px;
  }
  .IndicatorIcon {
    margin-bottom: 0;
    margin-right: 14px;
  }
  .IndicatorTitle {
    text-align: left;
  }
  .IndicatorParameters {
    text-align: left;
  }
  .IndicatorTextInfo {
    align-items: flex-start;
  }
}

@media screen and (min-width: 849.99px) {
  .HowToPlayButtonContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .ButtonContainer {
    position: relative;
    left: -46px;
    top: 50%;
  }
  .ParametersIndicatorContainer {
    padding: 0;
  }
}
