$white: #ffffff;
$black: #000000;
$dark_black: #2a2a2a;
$dark_gray: #3c3c3c;
$dark_gray_33: #333333;
$gray: #ababab;
$light_gray_EA: #eaeaea;
$light_gray_BE: #bebebe;

$blue: #3a4472;
$blue_13: #134fa5;
$blue_green: #497782;
$blue_sea: #2d9cdb;
$orange: #f9ab07;
$orange_light: #ffc00a;
$orange_dark: #ffb800;

$green_pea: #1e5629;
$green_pea2: #276333;
$green_pea3: #246916;
$green_christi: #71b60b;
$japanese_laurel: #0a7604;
$green_marsh: #7ba129;

$yellow_green: #bfd447; // winter - #E2FAFF; summer - #bfd447;
$green_yellow: #9ed427;
$lawn_green: #61ab10;
$lime_green: #347942;
$brown_dark: #424242;
$light_green: #8fb897; // winter - #2188c0; summer - #8fb897;
$lime_light_green: #c2e2b2;
$dark_green: #083210;
$packs_title_dark_green_background: #1C2F16; // winter - #2B91C9; summer - #1C2F16;
$packs_price_dark_red_background: #d32009;
$packs_price_dark_red_background_hover: #a82412; // custom color
$dark_green_mobile: #284220;// winter - #2B91C9; summer - #284220;
$green_mobile: #2C5120; // winter - #68b8e4; summer - #2C5120;
$light_green_mobile: #90b983; // winter - #def3ff; summer - #90b983;
$light_green_button_text: #0a7604;

$cinnabar: #e75b37;
$cognac: #aa3b1e;
$prairie_sand: #97371f;
$chocolate: #a24304;
$chocolate_red: #a94025;
$dark_tomato: #852f19;
$red: #fb4517;
$shiraz: #b50e38;

$dark_orchid: #9e0085;
$dark_violet: #8507ba;

$flamingo: #fcbfbf;
$watermelon: #f68b8b;

$alabaster: #fafafa; // input-bg
$alto: #dadada; // input-box-shadow
$tundora: #444444; // input-text
$light_gray: #dfdfdf;
$cod_gray: #161616;
$silver_chalice: #b1b1b1;
$selective_yellow: #ffb800;

$gold: #ffd600;
$honeysuckle: #eaff73;
$atlantis: #9ed427;
$aqua: #30f3ff;
$picton_blue: #56ccf2;
$bondi_blue: #0587b1;
$blaze_orange: #ff6b00;
$thunderbird: #d11717;
$mine_shaft: #303030;
$selective_yellow: #fcb201;
$hippie_green: #59834b; // winter - #2b91c9; summer - #59834b;
$dove_gray: #6b6b6b;
$woodland: #386629;
$wild_sand: #f5f5f5;
