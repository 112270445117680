@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.TransactionsPage {
  min-height: 100vh;
  background: $packs_title_dark_green_background;
}

.WalletContainer {
  width: 100%;
  padding: 155px 24px 24px;
}

.ProfileInfoIndicatorContainer {
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.ProfileInfoProgressIndicator {
  position: absolute;
  z-index: inherit;
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  background-image: url('../../../assets/control/progress_button_color.png');
  background-repeat: no-repeat;
  background-size: contain;
  animation: assetsProgressAnimation 2s
    infinite linear;
}

.Transactions {
  margin: 0 0 36px 0;
  max-width: 620px;
  width: 100%;
}

.TransactionsTitleWrapper {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1.5px solid #2c5120; // winter - #68B8E4; summer - #2c5120;
}

.TransactionsTitleMain {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: #ffffff;
  text-align-last: left;
}

.TransactionsItemContainer {
  margin-top: 10px;
  background: $dark_green_mobile;
  border-radius: 10px;
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 700px;
  justify-content: space-between;
  padding: 8px 16px 8px 8px;
}

.TransactionsLeftBlock {
  display: flex;
}

.WithdrawIcon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.TransactionsItemColumnContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TransactionsItemRightAlign {
  align-items: flex-end;
  justify-content: center;
}

.TransactionsTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: $white;
  margin-bottom: 5px;
}

.TransactionsPackStateTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 100%;
  color: $white;
}

.TransactionsPackErrorTitle {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
  color: $red;
}

.TransactionsStatusText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  text-align: right;
  color: #61ab10;
}

.TransactionsDateText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 100%;
  color: $white;
}

@keyframes assetsProgressAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1000px) {
  .WalletContainer {
    padding: 210px 30px 60px;
  }
}

@media screen and (min-width: 1300px) {
  .TransactionsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
