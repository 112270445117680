@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.CookiesPopUpContainer {
  z-index: 9;
  margin-left: 25px;
  margin-top: auto;
  margin-bottom: 15px;
  background: $woodland;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25),
    inset 0px -3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: all 0.5s;
  max-width: 1540px;
}

.CookiesPopUpText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 25px;
  color: #ffffff;
  max-width: 942px;
  padding: 30px 25px 30px 40px;
  transition: all 0.5s;
}

.ButtonsContainer {
  position: relative;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 30px 30px 0;
}

.ButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;

  &__Orange {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }

  &__Green {
    @include textBorder_2px_shadow(
      $green_pea
    );
  }
}

.DenyButtonWrapper {
  margin-top: 10px;
}

.DenyButtonText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  @include textBorder_2px_shadow($green_pea);
  cursor: pointer;
}

.ButtonContainer {
  margin: 10px;
}

.AcceptButton {
  width: 130px;
}

.ManageButton {
  width: 220px;
}

a.PrivacyAndTermsLink {
  color: $orange_dark;
  text-decoration: underline;
}

@media screen and (max-width: 1000px) {
  .CookiesPopUpContainer {
    flex-direction: column;
  }

  .CookiesPopUpText {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding: 20px 20px 10px;
  }

  .ButtonsContainer {
    padding: 0;
  }

  .DenyButtonWrapper {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 800px) {
  .CookiesPopUpContainer {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .CookiesPopUpContainer {
    margin-left: 10px;
    margin-right: 10px;
  }

  .CookiesPopUpText {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (min-width: 1760px) {
  .ButtonsContainer {
    flex-direction: row;
    padding: 30px 40px 30px 0;
  }

  .DenyButtonWrapper {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
