@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.ModalContainer {
  background-color: rgba($black, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 43px 10px 10px;
}

.Modal {
  margin: auto;
  position: relative;
  // height: 660px;
  display: flex;
  flex-direction: column;
  width: 718px;
  background: $white;
  border-radius: 40px;
  padding: 48px 48px 46px;
  padding-top: 69px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25), inset 0px -3px 6px rgba(255, 255, 255, 0.5), inset 0px -6px 6px rgba(0, 0, 0, 0.25);
  transition: all 0.5s;
  &__Stacking {
    background: $green_yellow;
    padding: 0px;
  }
  &__WithDescription {
    margin: 0;
  }
}

.ModalClose {
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: -30px;
  right: -30px;
  cursor: pointer;
  z-index: 2;
}

.DescriptionText {
  // margin: auto;
  margin-top: 12px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;

  color: $white;
  text-align: center;
  max-width: 600px;
}

// Mobile version Modal, after 718px
@media screen and (max-width: 1000px) {
  .ModalContainer {
    padding: 10px;
    padding-top: 70px;
    display: block;
    overflow-x: hidden;
    &__isUnityGameMobile {
      padding-top: 30px;
    }
  }
  
  .Modal {
    margin: auto;
    position: relative;
    width: calc(100vw - 30px);
    background: $white;
    border-radius: 40px;
    padding: 58px 24px 36px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25), inset 0px -3px 6px rgba(255, 255, 255, 0.5), inset 0px -6px 6px rgba(0, 0, 0, 0.25);
    transition: all 0.5s;
    &__isUnityGameMobile {
      width: calc(80vw - 30px);
      padding-top: 24px;
      padding-bottom: 12px;
    }
    &__Stacking {
      background: $green_yellow;
      padding: 0px;
    }
    &__WithDescription {
      // margin: 0;
    }
  }

  .ModalClose {
    top: -30px;
    right: -25px;
    & img {
      width: 70px;
      height: 70px;
    }
  }

  .DescriptionText {
    margin: auto;
  }

  // .TitleModal {
  //   top: -30px;
  //   height: 60px;
  //   width: 240px;
  // }

  .Title {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
  }
}