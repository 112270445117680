@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Title {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 100%;
  text-align: center;
  color: $white;
  margin-bottom: 20px;
}

.Text {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: $white;
  padding: 0 20px 31px;
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .Title {
    font-size: 45px;
  }

  .Text {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }
}

@media screen and (min-width: 950px) and (max-width: 999.99px) {
  .Title {
    font-size: 45px;
  }

  .Text {
    max-width: 590px;
  }
}

@media screen and (max-width: 949.99px) {
  .Title {
    font-size: 30px;
  }

  .Text {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    padding: 0;
    max-width: 590px;
  }
}
