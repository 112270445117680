@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.InfoTitle {
  margin-bottom: 60px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: $brown_dark;
}

.KeplerTitle {
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: $brown_dark;
}

.ButtonsContainer {
  display: flex;
  justify-content: center;
}

.ModalText {
  padding-top: 30px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $gray;
}

.TitleGreenButton {
  @include textBorder_4px_shadow($green_pea);

  &__android {
    @include textBorder_2px_shadow($green_pea);
  }
}

.TitleOrangeButton {
  @include textBorder_4px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow($chocolate);
  }
}

.TitleBlueButton {
  @include textBorder_4px_shadow($blue_13);

  &__android {
    @include textBorder_2px_shadow($blue_13);
  }
}

// Mobile version Modal, after 718px
@media screen and (max-width: 1000px) {
  .InfoTitle {
    margin-bottom: 40px;
  }

  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
  }
  .ButtonContainer:first-child {
    margin-bottom: 30px;
  }
  .ButtonContainer {
    height: 110px;
  }
}