@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.AccountPage {
  min-height: 100vh;
  background: $packs_title_dark_green_background;
  position: relative;
}

.WalletContainer {
  width: 100%;
  padding: 155px 20px 70px;
  position: relative;
}

.Profile {
  display: flex;
  // align-items: flex-start;
  // flex-direction: column;
  justify-content: space-between;
  padding-bottom: 18px;
  border-bottom: 1.5px solid $green_mobile;
  z-index: 15;
  position: relative;
}

.ProfileInfoWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &__noKepler {
    align-items: flex-start;
    flex-direction: column;
  }
}

.ButtonsProfileContainer {
  display: flex;
  justify-content: flex-start;
  &__noKepler {
    margin-top: 20px;
  }
}

.ProfileInfoContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 35px;
}

.LogoutIconImage {
  width: 15px;
  // winter - filter: invert(40%) sepia(77%) saturate(900%) hue-rotate(170deg) brightness(100%) contrast(100%);
}

.LogoutText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #4f7a42; // winter - #20adff; summer - #4f7a42;
  margin-right: 13px;
}

.LogoutButtonContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  z-index: 15;
  position: relative;
}

.OverviewInfoContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 15;
  position: relative;
}

.ProfileAvatarContainer {
  position: relative;
  width: 50px;
  cursor: pointer;
}

.ProfileInfo {
  margin-left: 20px;
}

.ProfileInfoIndicatorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 620px;
}

.ProfileInfoIndicatorContainer {
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  &_paidPacks {
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}

.ProfileInfoProgressIndicator {
  position: absolute;
  z-index: inherit;
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  background-image: url('../../../assets/control/progress_button_color.png');
  background-repeat: no-repeat;
  background-size: contain;
  animation: assetsProgressAnimation 2s
    infinite linear;
}

.ProfileInfoTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
}

.ProfileAccountEditContainer {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.ProfileAccountEdit {
  width: 30px;
  // winter - filter: invert(40%) sepia(77%) saturate(900%) hue-rotate(170deg) brightness(100%) contrast(100%);
}

.GradientBottomPlayButton {
  position: fixed;
  z-index: 18;
  width: 100%;
  bottom: 0;
  height: 160px;
}

.PlayButtonBlock {
  position: fixed;
  z-index: 19;
  right: 50%;
  transform: translate(50%, 0);
  bottom: 40px;
  display: flex;
  flex-direction: row; 
  align-items: center;
}

.PlayButtonContainer {
  cursor: pointer;
  width: 80px;
}

.PlayButton {
  width: 80px;
}

.PLayButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  @include textBorder_2px_shadow($light_green_button_text);
}

.PrivateUsernameButtonContainer {
  margin-left: 20px;
  margin-top: 0;
  cursor: pointer;
  width: 200px;
}

.PrivateUsernameButton {
  width: 200px;
  padding: 9px 10px 11px;
}

.PrivateUsernameButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  @include textBorder_2px_shadow($chocolate);
}

.OverviewContainer {
  width: 100%;
  margin: 20px 0 30px;
  max-width: 620px;
}

.OverviewTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  margin-bottom: 10px;
}

.SecondContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  // padding: 20px;
  // background: #2C5120;
  // border-radius: 10px;
  max-width: 620px;
  // margin-top: -32px;
  // z-index: 15;
}

.SendContainer {
  padding: 20px;
  background: #2C5120; // winter - #68B8E4; summer - #2c5120
  border-radius: 10px;
  max-width: 620px;
  z-index: 15;
  margin-bottom: 30px;
}

.ReferralContainer {
  padding: 20px;
  background: #2C5120; // winter - #68B8E4; summer - #2c5120
  border-radius: 10px;
  max-width: 620px;
  z-index: 15;
}

.ReferralTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  margin-bottom: 20px;
}

.TransferTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  margin-bottom: 10px;
}

.TokenChooseSendContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TokenText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  margin-bottom: 16px;
  margin-top: 16px;
}

.SendButtonContainer {
  margin-top: 24px;
  cursor: pointer;
  width: 180px;
}

.SendButton {
  width: 180px;
}

.ReferralCode {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.ReferralCodeTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ReferralCodeTotalTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ReceivedHoneyContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.ReferralNeedToBuyPackContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffb800; // winter - #ffffff; summer - #ffb800;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 10px 30px 30px;
  position: relative;
  max-width: 410px;
}

.ReferralNeedToBuyPackText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #1c2f16; // winter - #68B8E4; summer - #1c2f16;
  margin-bottom: 8px;
}

.BuyButtonContainer {
  width: 140px;
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translate(50%, 50%);
  z-index: 15;
}

.BuyButton {
  width: 140px;
}

.BuyButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  @include textBorder_2px_shadow($chocolate);
}

.ReferralText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  margin-right: 16px;
  &__MarginRight {
    &_10px {
      margin-right: 10px;
    }
  }
}

.ReferralDescription {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #8fb897; // winter - #def3ff; summer - #8fb897;
}

.ReferralCodeTextWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReferralCodeText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
}

.ReferralCode_ButtonContainer {
  position: relative;
}

.DescriptionReceivedHoneyContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.NFTPolygonContainer {
  margin: 0 0 30px;
  padding: 20px;
  background: #2c5120; // winter - #68B8E4; summer - #2c5120;
  border-radius: 10px;
  max-width: 620px;
  width: 100%;
}

.NFTPolygonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  margin-bottom: 20px;
}

.NFTPolygonAddress {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.NFTPolygonAddressTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.NFTPolygonText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  margin-right: 16px;
  &__MarginRight {
    &_10px {
      margin-right: 10px;
    }
  }
}

.NFTPolygonDescription {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #8fb897; // winter - #def3ff; summer - #8fb897;
}

.NFTPolygonAddressTextWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NFTPolygonAddressText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  word-break: break-all;
}

.NFTPolygonAddress_ButtonContainer {
  position: relative;
}

.DescriptionNFTPolygonContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

// NFTPolygon

.NFTPolygonContainer {
  margin: 0 0 30px;
  padding: 20px;
  background: #2c5120; // winter - #68B8E4; summer - #2c5120;
  border-radius: 10px;
  max-width: 620px;
}

.NFTPolygonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  margin-bottom: 20px;
}

.NFTPolygonAddress {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.NFTPolygonAddressTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.NFTPolygonText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  margin-right: 16px;
  &__MarginRight {
    &_10px {
      margin-right: 10px;
    }
  }
}

.NFTPolygonDescription {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #8fb897; // winter - #def3ff; summer - #8fb897;
}

.NFTPolygonAddressTextWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NFTPolygonAddressText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  word-break: break-all;
}

.NFTPolygonAddress_ButtonContainer {
  position: relative;
}

.DescriptionNFTPolygonContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

@media screen and (min-width: 500px) {
  .NFTPolygonAddressTitleContainer {
    justify-content: unset;
  }

  .NFTPolygonAddressTotalTitleContainer {
    justify-content: unset;
  }
  .NFTPolygonAddressText {
    font-weight: 700;
  }
}

.Transactions {
  margin-bottom: 30px;
  max-width: 620px;
  width: 100%;
}

.TransactionsTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TransactionsTitleMain {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  text-align-last: left;
}

.SeeAllButton {
  cursor: pointer;
}

.SeeAllButtonText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  text-decoration-line: underline;
  color: $yellow_green;
}

.TransactionsItemContainer {
  margin-top: 10px;
  background: $dark_green_mobile;
  border-radius: 10px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  padding: 10px 15px 15px 12px;
}

.TransactionsLeftBlock {
  display: flex;
}

.WithdrawIcon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  // winter - filter: invert(40%) sepia(77%) saturate(900%) hue-rotate(170deg) brightness(100%) contrast(100%);
}

.TransactionsItemColumnContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TransactionsItemRightAlign {
  align-items: flex-end;
  justify-content: center;
}

.TransactionsTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: $white;
  margin-bottom: 5px;
}

.TransactionsPackStateTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 100%;
  color: $white;
}

.TransactionsPackErrorTitle {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
  color: $red;
}

.TransactionsDescription {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14.4px;
  color: $white;
}

.TransactionsStatusText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  text-align: right;
  color: #61ab10; // winter - #def3ff; summer - #61ab10;
}

.TransactionsDateText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 100%;
  color: $white;
}

.OverviewWalletAddress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2c5120; // winter - #68B8E4; summer - #2c5120;
  border-radius: 10px;
  padding: 10px 12px;
  position: relative;
}

.OverviewWalletAddressText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  word-break: break-all;
}

.OverviewWalletAddress_ButtonContainer {
  position: relative;
}

.CopyIconMobile {
  width: 16px;
  margin-left: 12px;
  // winter - filter: invert(50%) sepia(13%) saturate(2977%) hue-rotate(158deg) brightness(97%) contrast(79%); 
}

.OverviewWalletAddress_InfoCopyContainer {
  bottom: calc(100% + 4px);
  left: 100%;
  transform: translate(-100%, 0);
}

.OverviewWalletAddress_ReferralContainer {
  bottom: 31px;
  left: -44px;
}

.Assets {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 620px;
  width: 100%;
}

.AssetsContainer {
  margin: 0 0 30px;
  max-width: 320px;
  width: 100%;
}

.AssetsTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #4e843c; // winter - white; summer - #4e843c;
  margin-bottom: 10px;
}

.AssetsCoinContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  transition: all 0.5s;
}

.AssetsCoinTitleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AssetsCoinTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  transition: all 0.5s;
}

.AssetsCoinPolygonBlock {
  position: relative;
  display: flex;
  align-items: center;
}

.AssetsHoneyQuantityBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.AssetsCoinWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.AssetsCoinQuantity {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-align: right;
  color: #ffffff;
  &__MarginBottom {
    &_5px {
      margin-bottom: 5px;
    }
  }
}

.HoneyPerBlockText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: $lawn_green; // winter - #def3ff; summer - $lawn_green;
}

.AssetsHoneyIcon {
  width: 30px;
}

.AssetsProgressIndicator {
  width: 20px;
  height: 20px;
  background-image: url('../../../assets/control/progress_button_color.png');
  background-repeat: no-repeat;
  background-size: contain;
  animation: assetsProgressAnimation 2s
    infinite linear;
}

@keyframes assetsProgressAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.SpecialPresaleSectionContainer {
  top: unset;
  width: 100%;
  // winter - background-color: #68B8E4;
  // winter - border-bottom-left-radius: 0;
  // winter - border-bottom-right-radius: 0;
}

@media screen and (min-width: 500px) {
  .ReferralCodeTitleContainer {
    justify-content: unset;
  }

  .ReferralCodeTotalTitleContainer {
    justify-content: unset;
  }

  .ReceivedHoneyContainer {
    justify-content: flex-start;
  }

  .ReferralNeedToBuyPackContainer {
    max-width: 395px;
  }

  .NFTPolygonAddressTitleContainer {
    justify-content: unset;
  }

  .NFTPolygonAddressTotalTitleContainer {
    justify-content: unset;
  }
  .NFTPolygonAddressText {
    font-weight: 700;
  }
}

.LabelInput {
  // font-family: Fira Sans;
  // font-style: normal;
  // font-weight: 900;
  // font-size: 20px;
  // line-height: 24px;
  // color: $dark_gray;
  margin-bottom: 8px;
  font-family: 'Fira Sans';
  // font-style: normal;
  // font-weight: 400;
  // font-size: 16px;
  // line-height: 100%;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;

  color: #ffffff;
  margin-right: 16px;
  &__MarginRight {
    &_10px {
      margin-right: 10px;
    }
  }
}

.Input_InputContainer {
  margin: 0;
  background-color: #eeeeee;
  border-radius: 10px;
  box-shadow: inset 0px 2px 0px #cccccc;
  border: 1px solid #ffffff !important;
  flex: 0;
  width: 280px;
  &__Recipient {
    width: 100%;
  }
}

.Input_Container {
  background-color: transparent;
  height: 160px;
}

.Input_Input {
  box-shadow: unset;
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 38px;
  width: auto !important;
  &__Recipient {
    width: 100% !important;
  }
  text-align: left;

  &::placeholder {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    text-align: left;
    color: #8e8b8b;
  }
}

.InputError {
  margin-top: 4px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: $red;
}

@media screen and (min-width: 645px) {
  .Profile {
    flex-direction: row;
    align-items: center;
  }

  .ButtonsProfileContainer {
    margin-top: 0;
  }

  .ProfileInfoWrapper {
    align-items: center;
    flex-direction: row;
  }

  .OverviewContainer {
    margin-top: 40px;
  }

  .WalletContainer {
    padding: 155px 30px 60px;
  }

  .BuyButtonContainer {
    bottom: unset;
    right: -160px;
    transform: unset;
  }

  .ReferralNeedToBuyPackText {
    margin-bottom: 0;
  }

  .ReferralNeedToBuyPackContainer {
    padding: 13px 20px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1000px) {
  .WalletContainer {
    padding: 210px 30px 60px;
  }
}

@media screen and (min-width: 1315px) {
  .OverviewContainerWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 15;
    position: relative;
  }

  .SecondContainer {
    // margin: 40px 0 30px;
    margin: 68px 0 0;
  }

  .ReferralContainer {
    margin: 68px 0 0;
    margin-top: 0px;
  }

  .OverviewInfoContainer {
    margin-right: 60px;
  }

  .Transactions {
    max-width: 560px;
    margin-bottom: 0;
  }

  .OverviewContainer {
    max-width: 560px;
  }

  .NFTPolygonContainer {
    max-width: 560px;
  }

  .Assets {
    max-width: 560px;
  }

  .ProfileInfoIndicatorWrapper {
    max-width: 560px;
  }

  .ProfileInfoIndicatorContainer {
    &_paidPacks {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 330px) {
  .PlayButtonBlock {
    flex-direction: column; 
  }
  
  .PrivateUsernameButtonContainer {
    margin-left: 0;
    margin-top: 20px;
  }
}
