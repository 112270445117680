@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.PopUpContainer {
  padding: 60px 0;
  background-color: rgba($black, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
  top: 0;
  display: flex;
  overflow: auto;
}

.PopUp {
  margin: auto;
  position: relative;
  height: 280px;
  width: 830px;
  display: flex;
  justify-content: center;
  // background: $white;
  // border-radius: 15px;
  // padding: 48px 72px 56px 48px;
  // box-shadow: 0px 11px 15px rgba($black, 0.2), 0px 24px 38px rgba($black, 0.14),
    // 0px 9px 46px rgba($black, 0.12);

  background: #FDFBD3;
  border: 2px solid #C1A55C;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25), inset 0px -10px 4px #E9D7A7;
  border-radius: 60px;
  transition: all 0.5s;
}

.TitlePopup {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -40px;
  display: flex;
  height: 80px;
  width: 546px;
}

.PopUpClose {
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: -40px;
  right: -40px;
  cursor: pointer;
  z-index: 1;
}

.PopUpTitle {
  @include title;
  text-transform: uppercase;
  font-size: 30px;
  padding-bottom: 21px;
  transition: all 0.5s;
}

.PopUpText {
  padding: 80px;
  position: relative;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $chocolate;
  &_isError {
    display: block;
    overflow: auto;
  }
}

.ButtonsContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  // margin-left: auto;
  // margin-right: auto;
  right: 0;
  left: 0;
  bottom: -44px;
}

.ButtonContainer {
  position: relative;
  display: flex;
  // position: absolute;
  // margin-left: auto;
  // margin-right: auto;
  // right: 0;
  // left: 0;
  // bottom: -44px;
}

// .TitleGreenButton {
//   @include textBorder_4px_shadow($green_pea);
// }

.TitleButton {
  &__GREEN {
    @include textBorder_2px_shadow($green_pea);
    &__android {
      @include textBorder_2px_shadow($green_pea);
    }
  }

  &__ORANGE {
    @include textBorder_2px_shadow($chocolate);
    &__android {
      @include textBorder_2px_shadow($chocolate);
    }
  }
}

// TODO: Mobile variant 600px
@media screen and (max-width: 1000px) {
  .PopUpContainer {
    padding: 10px;
    padding-top: 40px;
    overflow-x: hidden;
  }
  .PopUp {
    width: calc(100vw - 30px);
    padding: 0px 24px 0px;
    border-radius: 40px;
    height: auto;
  }
  .PopUpClose {
    top: -40px;
    right: -25px;
    & img {
      width: 70px;
      height: 70px;
    }
  }
  .TitlePopup {
    top: -30px;
    height: 60px;
    width: 240px;
  }
  .Title {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    line-height: 30px;
  }
  .PopUpText {
    padding-top: 58px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 90px;
  
    /* Коричневый */
    color: $chocolate;
  }

  .ButtonsContainer {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    right: 0;
    left: 0;
    bottom: -44px;
  }
  .ButtonContainer {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 600px) {
  .TitlePopup__contactUs {
    height: 90px;
    top: -45px;
  }
  .PopUpText__contactUs {
    padding-top: 73px;
  }
}
