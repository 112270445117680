@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.MainContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 161px;
  position: absolute;
  width: 100%;
  z-index: 12;
  padding-right: 70px;
  transition: all 0.5s;
  padding: 35px 35px 0;
}

.MainPageLink {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &__hide {
    display: none;
  }
}
.ArrowLeftIcon {
  margin-right: 12px;
}

.MainPageText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: $white;
}

.ButtonLinks {
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.PlayerMenuContainerBlock {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  &__hide {
    display: none;
  }
  &__Cursor {
    &_unset {
      cursor: unset;
    }
  }
}

.PlayerImage {
  display: flex;
  justify-content: center;
  margin-left: 16px;
  position: relative;
}

.PlayerMenuButton {
  &:active {
    background: #b93b1b;
    box-shadow: 0px 3px 5px
        rgba(0, 0, 0, 0.25),
      inset 0px 4px 2px #ff6725,
      inset 0px -4px 4px #d43a12;
    border-radius: 10px;
  }
  &__hide {
    display: none;
  }
}

.MenuContainer {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.5s;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    position: relative;
    right: -16px;
    text-decoration: none; // for Mozilla

    &_SellingPack {
      background: #fb4517;
      box-shadow: 0px 3px 5px
          rgba(0, 0, 0, 0.25),
        inset 0px 4px 2px #ff6725,
        inset 0px -4px 4px #d43a12;
      border-radius: 10px;
    }
  }

  &__item:active {
    background: #b93b1b;
    box-shadow: 0px 3px 5px
        rgba(0, 0, 0, 0.25),
      inset 0px 4px 2px #ff6725,
      inset 0px -4px 4px #d43a12;
    border-radius: 10px;
  }

  &__title {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 8px 16px;
    display: flex;
    text-align: center;
    color: $white;
  }

  &__Display {
    &_none {
      display: none;
    }
  }
}

@media (hover: hover) {
  .PlayerMenuButton:hover,
  .MenuContainer__item:hover {
    background: #fb4517;
    box-shadow: 0px 3px 5px
        rgba(0, 0, 0, 0.25),
      inset 0px 4px 2px #ff6725,
      inset 0px -4px 4px #d43a12;
    border-radius: 10px;
    cursor: pointer;
  }

  .PlayerMenuButton:active,
  .MenuContainer__item:active {
    background: #b93b1b;
    box-shadow: 0px 3px 5px
        rgba(0, 0, 0, 0.25),
      inset 0px 4px 2px #ff6725,
      inset 0px -4px 4px #d43a12;
    border-radius: 10px;
    cursor: pointer;
  }
}

.LanguageContainer {
  height: 0;
  position: relative;
}

.HoneyWoodLogo {
  width: 121px;
  margin-bottom: 36px;
}

@media screen and (max-width: 1400px) {
  .MenuContainer__title {
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (max-width: 1350px) {
  .PlayerMenuButton {
    display: none;
  }
}

@media screen and (max-width: 1170px) {
  .MenuContainer__title {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1120px) {
  .HoneyWoodLogo {
    width: 100px;
  }

  .MenuContainer__title {
    font-size: 15px;
    line-height: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .MenuContainer__title {
    font-size: 14px;
  }
}
