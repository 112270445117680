@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.TextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.SecondsContainer {
  &__Seconds {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
  }
}

.DigitsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AvailableText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  color: #BFD447;
}

.DigitsStyle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  color: #BFD447;
}

@media screen and (max-width: 649.99px) {
  .SecondsContainer {
    &__Seconds {
      width: 30px;
    }
  }
}
