@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // z-index: 1;
}

.BackgroundImagesContainer {
  display: flex;
  overflow: hidden;
}

.BackgroundMobileImage {
  width: 420px; // winter - 420px; summer - 360px;
  display: initial;
}

.BackgroundTabletImage {
  width: 1020px;
  display: none;
}

.BackgroundDesktopImage {
  width: 1920px;
  display: none;
}

.MonetaTodayLogo {
  position: absolute;
  left: 71px;
  top: -10px;
}

.TitleContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 96px;
  z-index: 15;
}

.Title {
  font-size: 100px;
}

.SocialIconsContainer {
  margin-top: 25px;
  z-index: 10 !important;
}

.Description {
  position: relative;
  z-index: 2;
  margin-top: 30px;

  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: $black;
}

.InfoContainer {
  width: 100%;
  position: absolute;
  top: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ButtonsContainer {
  position: absolute;
  overflow: hidden;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ButtonContainer {
  margin-top: 100px;
}

.TitleGreenButton {
  @include textBorder_2px_shadow(
    $light_green_button_text
  );

  &__android {
    @include textBorder_2px_shadow(
      $light_green_button_text
    );
  }
}

.PlayButtonSoon {
  margin-top: 18px;
}

.GradientBottomImage {
  position: absolute;
  bottom: -2px;
  width: 100%;
}

.LeavesBottomImage {
  position: absolute;
  width: 100%;
  bottom: calc(0px);
}

.HoneyWoodLogoContainer {
  position: relative;
}

.HoneyWoodLogo {
  width: 121px;
}

@media screen and (min-width: 419.99px) {
  .BackgroundMobileImage {
    display: none;
  }

  .BackgroundTabletImage {
    display: initial;
  }

  .BackgroundImagesContainer {
    width: 100%; // makes the image view from the beginning
  }
}

@media screen and (min-width: 1000px) {
  .HoneyWoodLogoContainer {
    display: none;
  }

  .TitleContainer {
    margin-top: 177px;
  }
}

@media screen and (min-width: 1019.99px) {
  .BackgroundTabletImage {
    width: 100%;
  }
}

@media screen and (min-width: 1418px) {
  .BackgroundTabletImage {
    display: none;
  }

  .BackgroundDesktopImage {
    display: initial;
  }
}

@media screen and (min-width: 1919.99px) {
  .BackgroundDesktopImage {
    width: 100%;
  }
}
