@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.ButtonsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
  margin-top: 23px;
}

.TitleGreenButton {
  @include textBorder_3px_shadow($green_pea); // Safari/Chrome - 3px good, but not the best for Safari

  &__android {
    @include textBorder_2px_shadow($green_pea);
  }
}

.TitleOrangeButton {
  @include textBorder_3px_shadow($chocolate); // Safari/Chrome - 3px good, but not the best for Safari

  &__android {
    @include textBorder_2px_shadow($chocolate);
  }
}

.Input_InputContainer {
  margin-top: 0px;
  border-radius: 15px;
  border: 1px solid transparent !important;
  box-shadow: inset 0px 2px 0px #cccccc;
  background-color: #eaeaea;
}

.Input_Container {
  background-color: transparent;
  height: 140px;
  margin-top: 8px;
}
.Input_InvestContainer {
  background-color: transparent;
  margin-bottom: 15px;
}

.Input_Input {
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 70px;

  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $gray;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.LabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  color: $dark_gray;
}

@media screen and (max-width: 750px) {
  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
  }
  .ButtonContainer {
    height: 120px;
  }
}

// Mobile version Modal, after 718px
@media screen and (max-width: 1000px) { 
  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
    margin-top: 3px;
  }
  .ButtonContainer:first-child {
    margin-bottom: 30px;
  }

  .Input_Container {
    background-color: transparent;
    height: 130px;
  }
  .Input_InputContainer {
    flex: 0;
  }

  .Input_Input {
    background-color: transparent;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    height: 48px;
    
    &::placeholder {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: $gray;
    }
  }

  .LabelInput {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 360px) {
  .ButtonContainer {
    height: 90px;
  }
}