@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.InfoTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: $brown_dark;
  margin: 20px;
}

.DescriptionModal {
  margin-top: 14px;
  font-family: 'Fira Sans';
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $brown_dark;
}

.InputContainerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ButtonsContainer {
  // margin-top: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.ButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;

  &__Green {
    @include textBorder_2px_shadow(
      $green_pea
    );
  }
}

.Button {
  width: 210px;
}

.Input_InputContainer {
  margin-top: 0px;
  border-radius: 12px;
  border: 1px solid transparent !important;
  box-shadow: inset 0px 2px 0px #cccccc;
  background-color: #eaeaea;
}

.Input_Container {
  background-color: transparent;
  height: 140px;
  margin-top: 8px;
  padding: 0;
  max-width: 440px;
  min-width: 340px;
  align-self: center;
}

.Input_Input {
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  height: 34px;

  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #676767;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.LabelInput {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  color: $brown_dark;
  margin: 10px 0 10px;
}

@media screen and (max-width: 1000px) {
  .Input_Container {
    background-color: transparent;
    height: 130px;
  }

  .Input_InputContainer {
    flex: 0;
  }

  .Input_Input {
    background-color: transparent;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    height: 34px;

    &::placeholder {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: $gray;
    }
  }

  .LabelInput {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 100%;
    text-align: left;
    color: $brown_dark;
  }
}

@media screen and (max-width: 550px) {
  .ButtonContainer {
    width: 210px;
    margin: 10px 0;
  }

  .ButtonsContainer {
    flex-direction: column;
  }

  .Input_Container {
    max-width: 250px;
    min-width: 250px;
  }
}