@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.InfoTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: $brown_dark;
  margin: 20px;
}

.KeplerTitle {
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: $brown_dark;
}

.KeplrButton {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.ButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.ModalText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: $dove_gray;
}

.ButtonContainer {
  width: 210px;
  margin-bottom: 20px;
}

.ButtonContainer:last-child {
  margin-bottom: 0;
}

.Button {
  width: 210px;
}

.ButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  &__Green {
    @include textBorder_2px_shadow(
      $green_pea
    );
  }
  &__Orange {
    @include textBorder_2px_shadow(
      $chocolate
    );
  }
  &__Blue {
    @include textBorder_2px_shadow($blue_13);
  }
}
