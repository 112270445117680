@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.AvatarCostContainer {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.AvatarCostText {
  display: flex;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  color: $dark_gray;
}
.NotEnoughHoneyText {
  // display: flex;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: $red;
  text-align: center;
  margin-bottom: 20px;
}
.HoneyIconCost {
  margin-left: 10px;
  margin-right: 6px;
  width: 20px;
  height: 20px;
}
.InfoTitle {
  margin-bottom: 60px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: $brown_dark;
}
.AvatarIconsContainer {
  margin-top: 12.5px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-self: center;
}
.AvatarItemContainer {
  position: relative;
}
.AvatarGrayCircle {
  position: absolute;
  top: 14px;
  left: 10px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.AvatarOrangeCircle {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 94px;
  height: 94px;
  border-radius: 47px;
  background-color: white;
  border: 4px solid $orange_dark;
  z-index: 1;
}
.AvatarIconHover {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 94px;
  height: 94px;
  border-radius: 47px;
  background-color: white;
  border: 4px solid #dae7ec;
  z-index: 1;
}
.AvatarIconDefault {
  position: relative;
  margin: 10px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  z-index: 2;
  background-color: white;
}
.AvatarIconPriceContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}
.AvatarIconContainer {
  position: relative;
  margin: 10px;
  width: 80px;
  height: 80px;
  z-index: 2;
  cursor: pointer;
}
.AvatarIcon {
  position: relative;
  width: 80px;
  height: 80px;
}
.AvatarPriceContainer {
  display: flex;
  justify-content: center;
}
.AvatarPriceText {
  display: flex;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  color: $brown_dark;
  margin-left: 3px;

  &__stock {
    margin-left: 0px;
    color: #ADADAD;
  }
  &__inUse {
    margin-left: 0px;
    color: #61AB10;
  }
}
.HoneyIcon {
  display: flex;
  width: 20px;
  height: 20px;
}
.AvatarIconLoading {
  position: relative;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;

  background-image: url('../../../assets/control/progress_button_color.png');
  background-repeat: no-repeat;
  background-size: contain;
  animation: assetsProgressAnimation 2s infinite linear;
}
@keyframes assetsProgressAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ButtonsContainer {
  display: flex;
  justify-content: center;
}
.ButtonTitleContainer {
  padding-top: 8px;
}

.ModalText {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Fira Sans;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: $dove_gray;
}

.TitleGreenButton {
  @include textBorder_2px_shadow($green_pea);

  &__android {
    @include textBorder_2px_shadow($green_pea);
  }
}

.TitleOrangeButton {
  @include textBorder_2px_shadow($chocolate);

  &__android {
    @include textBorder_2px_shadow($chocolate);
  }
}

.TitleBlueButton {
  @include textBorder_2px_shadow($blue_13);

  &__android {
    @include textBorder_2px_shadow($blue_13);
  }
}

@media screen and (max-width: 750px) {
  .ButtonContainer {
    height: 110px;
  }
}

// Mobile version Modal, after 718px
@media screen and (max-width: 1000px) {
  .InfoTitle {
    margin-bottom: 40px;
  }

  .ButtonsContainer {
    flex-direction: column;
    align-items: center;
  }
  .ButtonContainer:first-child {
    margin-bottom: 30px;
  }
  .ButtonContainer {
    height: 110px;
  }
}

@media screen and (max-width: 360px) {
  .ButtonContainer {
    height: 90px;
    &__android {
      height: 110px;
    }
  }
}
// style for avatar icons
@media screen and (max-width: 660px) {
  .AvatarIconsContainer {
    margin-top: 12.5px;
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
    max-width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-self: center;
  }
  .AvatarGrayCircle {
    top: 11.5px;
    left: 5px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
  .AvatarOrangeCircle {
    top: 0.5px;
    left: -2px;
    width: 74px;
    height: 74px;
    border-radius: 37px;
  }
  .AvatarIconHover {
    top: 0.5px;
    left: -2px;
    width: 74px;
    height: 74px;
    border-radius: 37px;
  }
  .AvatarIconDefault {
    margin: 7.5px 5px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
  .AvatarIconContainer {
    margin: 7.5px 5px;
    width: 60px;
    height: 60px;
  }
  .AvatarIcon {
    width: 60px;
    height: 60px;
  }
  .AvatarIconLoading {
    top: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
  }
}

.ErrorText {
  margin-top: 12.5px;
  margin-bottom: 20px;
  @include font($size: 20px, $family: Roboto, $weight: 400);
  color: $red;
}
