@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  position: relative;
  top: -20px;
  z-index: 2;
  background: $packs_title_dark_green_background;
  border-radius: 20px;
  padding: 40px 20px;
}

.TitleContainer {
  max-width: 861px;
}

.Subtitle {
  margin-bottom: 20px;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: $white;
  max-width: 861px;
}

.FaqBlocksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 861px;
}

.FaqItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 10px;
  animation: fadeIn 1s linear forwards;
}

.FaqItemContainer:last-child {
  margin-bottom: 0;
}

.QuestionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  cursor: pointer;
}

.AnswerContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px 20px;
  transition: all 0.5s;
  overflow: hidden;
}

.QuestionText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: $mine_shaft;
  text-align: left;
  margin-right: 10px;
}

.AnswerText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  line-height: 120%;
  color: $mine_shaft;
  text-align: left;
  width: 100%;
  &__marginBottom {
    &_20px {
      margin-bottom: 20px;
    }
  }
}

.ListStyle {
  margin-left: 16px;
}

.AnswerPartText {
  margin-bottom: 16px;
}

li > b {
  font-weight: bold;
}

p > b {
  font-weight: bold;
}

p > i {
  font-style: italic;
}

p > a {
  width: 100%;
  word-wrap: break-word;
}

ul li::before {
  content: '\02022';
  color: $selective_yellow;
  font-weight: 900;
  font-size: 20px;
  display: inline-block;
  width: 16px;
  margin-left: -16px;
  position: relative;
  top: 2.5px;
}

.MarginBottom {
  &_50px {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 700px) {
  .Container {
    padding: 30px 80px 60px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 1000px) {
  .Container {
    padding: 30px 80px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
