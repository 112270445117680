@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.NicknameCostContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.NicknameCostText {
  display: flex;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  color: $dark_gray;
}

.HoneyIcon {
  margin-left: 16px;
  margin-right: 8px;
  width: 28px;
  height: 30px;
}

.ButtonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
  margin-top: 23px;
  flex-direction: column;
}

.Input_InputContainer {
  margin-top: 0px;
  border-radius: 15px;
  border: 1px solid transparent !important;
  box-shadow: inset 0px 2px 0px #cccccc;
  background-color: #eaeaea;
}

.Input_Container {
  background-color: transparent;
  height: 140px;
  margin-top: 8px;
}

.Input_Input {
  background-color: transparent;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 50px;

  &::placeholder {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $gray;
  }
}

.Input_InputButtonShow {
  background-color: transparent;
}

.LabelInput {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 100%;
  text-align: left;
  color: $brown_dark;
  margin: 10px 0;
}

.ChangeNicknameAttentionContainer {
  margin-top: 32px;
  background: $light_gray_EA;
  border-radius: 20px;
  padding: 21px 30px 19px;
}

.ChangeNicknameAttention {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $brown_dark;
}

.Button {
  width: 200px;
}

.ButtonTitle {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  &__Green {
    @include textBorder_2px_shadow(
      $green_pea
    );
  }
}

@media screen and (max-width: 1000px) {
  .ButtonContainer:first-child {
    margin-bottom: 30px;
  }

  .Input_Container {
    background-color: transparent;
    height: 130px;
  }

  .Input_InputContainer {
    flex: 0;
  }

  .Input_Input {
    background-color: transparent;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    height: 48px;

    &::placeholder {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: $gray;
    }
  }

  .LabelInput,
  .NicknameCostText {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }
}
