@import '@src/styles/mixins.scss';
@import '@src/styles/colors.scss';

.CheckBoxComponent {
  &__Container {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    &_disabled {
      cursor: default;
    }
    &:focus-within {
      outline: none !important;
    }
  }

  &__Title {
    padding: 0 20px;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $brown_dark;
    // &_selected {
    //   // @include font();
    // }
    &_disabled {
      color: #999999;
    }
  }
}
