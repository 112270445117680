@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  position: relative;
  // width: 100%;
  // height: 100%;
  z-index: 10;
  width: 180px;
  height: 100px;
  // cursor: pointer;
}

.OuterContainer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.InnerContainer {
  position: absolute;
  left: 18.5px;
  top: 10px;
  width: calc(100% - 37px);
  height: calc(100% - 32px);
}

.Title {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 40px;

  display: flex;
  align-items: center;
  text-align: center;

  // disable select text
  @include noselected();
  color: $white;
  fill: $white;
  &__MEDIUM {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
  }
  &__SMALL {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
  }
}

.TitleTransparent {
  color: transparent;
  position: absolute;
}

.TitleContainer {
  height: 100%;
  padding-top: 8px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  &__MEDIUM {
    padding-top: 16px;
  }
  &__SMALL {
    padding-top: 0px;
  }
}
.TitleStyle {
  display: flex;
  position: relative;
}

.ButtonOpacitySVG {
  display: none;
}

.ButtonSVGStyle:hover {
  cursor: pointer;

  .ButtonOpacitySVG {
    display: block;
  }
}

.ButtonSVGStyle:active {
  .ButtonOpacitySVG {
    display: none;
  }
  .ButtonInnerSVG {
    filter: url(#filter1_ddii_281_197);
    -webkit-filter: url(#filter1_ddii_281_197);
    & path {
      fill: url(#paint0_linear_281_197);
    }
  }
  .ButtonInnerSVG__small {
    filter: url(#filter1_ddii_281_197);
    -webkit-filter: url(#filter1_ddii_281_197);
    & path {
      fill: url(#paint0_linear_281_097);
    }
  }
}

.ActiveTransform:active {
  transform-origin: center center;
  transform: scale(0.997);
}

.SoonImage {
  position: absolute;
  z-index: inherit;
  top: -12px;
  left: 10px;
  width: 70px;
  &__MEDIUM {
    top: -12px;
    left: 10px;
    width: 100px;
  }
}

.ProgressIndicator {
  position: absolute;
  z-index: inherit;
  top: 18px;
  width: 30px;
  height: 30px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  background-image: url('../../assets/control/progress_button.png');
  background-repeat: no-repeat;
  background-size: contain;
  animation: progressAnimation 2s infinite linear;
  &__MEDIUM {
    top: 32px;
    width: 50px;
    height: 50px;
  }
  &__SMALL {
    top: 18px;
    width: 30px;
    height: 30px;
  }
  &__android {
    top: 20px;
    width: 50px;
    height: 50px;
  }
}

@keyframes progressAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* For Presale Html */
.CostContainer {
  // position: relative;
  display: flex;
  justify-content: center;
  // align-items: center;
  // margin-bottom: 32px;
}
// .TitleNewTotalText {
//   font-family: Fira Sans;
//   font-weight: 900;
//   font-size: 40px;
//   line-height: 48px;
//   text-align: center;
//   // margin-bottom: 16px;
//   color: $red;
// }
.TitleOldTotalTextContainer {
  margin-left: 8px;
  // position: relative;
  // flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TitleOldTotalText {
  // display: flex;
  // flex: 1;
  // white-space: pre;
  font-family: Fira Sans;
  font-weight: 900;
  font-size: 28px;
  line-height: 32px;
  // text-align: center;
  // margin-bottom: 16px;
  color: $green_pea;
  // text-shadow: unset;
  // @include textBorder_2px_shadow($green_pea);
  // @include textBorder_2px_shadow(#1A9E39);
  // color: $white;
  text-shadow: initial;
}
.TitleOldTotalLine {
  position: absolute;
  width: 50%;
  // width: 38px;
  // height: 100%;
  // display: flex;
  // flex: 0;
}

.PriceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.PriceDiscountContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fb4517;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25), inset 0px 4px 2px #ff6725, inset 0px -4px 4px #d43a12;
  border-radius: 8px;
  padding: 0 6px;
}
.TetherUsdtIcon {
  width: 25px;
  // position: relative;
  // top: 1px;
  &_Crossed {
    width: 22px;
  }
}

.TitleOldTotalTextUSDTContainer {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TitleOldTotalUSDTText {
  font-family: Fira Sans;
  font-weight: 900;
  font-size: 28px;
  line-height: 32px;
  color: #782201;
  text-shadow: initial;
}

.PriceUSDTContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.PriceDiscountUSDTContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
}
.PriceDiscountUSDTText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  @include textBorder_2px_shadow($chocolate);
}
.KeplerIcon {
  width: 55px;
}
.HoneyIcon {
  width: 55px;
}
.PriceText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}
.PriceDiscountText {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  @include textBorder_2px_shadow($chocolate);
}
