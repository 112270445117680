@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/variables.scss';

.Container {
  background-color: #ffffff;
  min-width: 100vw;
  min-height: 100vh;
  padding: 24px 18px;
}

.Title {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  margin-bottom: 24px;
}